
import React = require('react');

interface IProps {
    path: string;
}
interface IState {

}
class Thumbnail extends React.Component<IProps, IState> {
    render() {
        return (
            <div className="thumbnail">
                {this.getImageData()}
            </div>
        );
    }

    getImageData(): React.ReactNode {
        const path = this.props.path;
        if (path === 'pdf') {
            return <span style={{ fontSize: '150px', color: '#cacaca' }} className={'material-icons'}>picture_as_pdf</span>;
        }
        return <img src={path} alt="image not loaded"></img>;
    }
}

export default Thumbnail;
