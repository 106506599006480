import Input from './Input';
import Thumbnail from './Thumbnail';
import SingleSelect from './SingleSelect';
import MultiSelect from './MultiSelect';
import MultiCheckBoxes from './MultiCheckBoxes';
import TextArea from './TextArea';
import DateTime from './DateTime';
import CheckBox from './CheckBox';
import CollectionComponent from './CollectionComponent';
import CollectionEditComponent from '../editCollection/CollectionEditComponent';
import { ActionT } from '../Types';
import { IMeta } from '../../index/IndexModel';
import React = require('react');

export interface IFrontComponentProps {
    onModelChange: (key: string, value: any) => void;
    classNames?: string;
    key: string;
    onModelClick?: ActionT<any>;
}

class ComponentFactory {
    static CustomFactory: (property: any, value: any) => any;

    static RegisterFactory(f: (property, value) => any) {
        ComponentFactory.CustomFactory = f;
    }

    static GetRawComponent(property, value, frontProps: IFrontComponentProps) {
        return this.GetComponent(property, value, frontProps, false);
    }

    static GetComponent(property, value, frontProps: IFrontComponentProps, withLabel: boolean = true) {
        if (ComponentFactory.CustomFactory) {
            const component = ComponentFactory.CustomFactory(property, value);
            if (component != null) { return component; }
        }

        const meta = property.meta as IMeta;
        if (!meta.visible) { return null; }
        const componentName = meta.component;
        const action = frontProps.onModelChange; ;
        const classNames = frontProps.classNames;

        if (componentName === 'CollectionEditComponent') {
            return (
                <CollectionEditComponent items={value} meta={property.Meta}
                    property={property}
                    valueChangedAction={frontProps.onModelChange}
                    classNames={frontProps.classNames} />

            );
        }

        if (componentName === 'CollectionComponent') {
            const subComponents = property.meta.subComponents || [];
            return (<CollectionComponent items={value} meta={property.meta} property={property} valueChangedAction={frontProps.onModelChange} classNames={frontProps.classNames} onItemClick={frontProps.onModelClick}>
                {
                    subComponents.map(c => this.GetComponent({ Meta: { Component: c } }, value, frontProps))
                }
            </CollectionComponent>);
        }
        if (componentName === 'SingleSelect') {
            return (
                <SingleSelect
                    items={property.viewModelProperty.items}
                    value={value}
                    meta={property.meta}
                    valueChangedAction={action}
                    classNames={classNames}
                    withLabel={withLabel}
                />
            );
        }
        if (componentName === 'MultiSelect') {
            return (
                <div className="data-input single-select">
                    <MultiSelect items={property.viewModelProperty.items}
                        value={value}
                        withLabel={withLabel}
                        meta={property.meta} valueChangedAction={action}></MultiSelect>
                </div>
            );
        }
        if (componentName === 'MultiCheckBoxes') {
            return (
                <div className="data-input single-select">
                    <MultiCheckBoxes items={property.viewModelProperty.items} meta={property.meta} valueChangedAction={action}></MultiCheckBoxes>
                </div>
            );
        }

        if (componentName === 'DateTime') {
            return (
                <DateTime property={meta} key={meta.name} valueObject={{ value: value, key: meta.name }} valueChangedAction={action} classNames={classNames} />
            );
        }

        if (componentName === 'Thumbnail') {
            return (
                <div className="data-input thumbnail">
                    <Thumbnail path={value} key={meta.name} />
                </div>
            );
        }

        if (componentName === 'TextArea') {
            return (
                <TextArea
                    property={meta} key={meta.name + frontProps.key}
                    valueObject={{ value: value, key: meta.name }}
                    withLabel={withLabel}
                    valueChangedAction={action}
                    classNames={classNames}

                />
            );
        }

        if (componentName === 'CheckBox') {
            return (
                <CheckBox
                    meta={meta}
                    key={meta.name}
                    valueObject={{ value: value, key: meta.name }}
                    valueChangedAction={action}
                    classNames={classNames}
                    withLabel={withLabel}
                />
            );
        }

        // if (componentName === "Text") {
        //     return (
        //         <Text property={meta}
        //             inputType={componentName}
        //             key={meta.Name}
        //             valueObject={{ value: value, key: meta.Name }}
        //             valueChangedAction={action}
        //             classNames={classNames} />
        //     )
        // }
        // for simple single input using componentName as input type
        return <Input meta={meta}
            key={frontProps.key || ''}
            inputType={componentName || 'text'}
            valueObject={{ value: value, key: meta.name }}
            valueChangedAction={action}
            classNames={classNames}
            withLabel={withLabel}

        />;
    }
}

export default ComponentFactory;
