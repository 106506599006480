import { IMeta } from '../../index/IndexModel';
import { ValueChanged } from '../Types';
import BaseComponent, { IBaseProps, IBaseState } from './BaseComponent';
import InputBase from './InputBase';
import Comment from './Comment';
import * as style from '../../../../styledComponent/style.css';
import classes from '../../tools/classes';
import React = require('react');

interface IProps extends IBaseProps {
    meta: IMeta;

    classNames: string;
    inputType: string;
}

interface IState extends IBaseState {

}

export default class Input extends BaseComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {

        };
    }

    inputValueChanged(event: any) {
        const newValue = event.target.value;

        if (this.props.valueChangedAction && !this.props.meta.readOnly) {
            this.props.valueChangedAction(this.props.meta.name, newValue);
        }
    }

    render() {
        const classNames = classes(style.dataInput, this.props.classNames || '');
        const meta = this.props.meta;
        const inputValue = this.props.valueObject.value || '';
        const inputType = this.getInputType(this.props.inputType, meta);

        return (
            <div className={classNames} key={this.props.meta.name}>
                {this.props.withLabel && <div className={style.dataLabel}>
                    {meta.displayName}
                </div>}
                <InputBase
                    classNames={classes(meta.readOnly ? style.readOnly : '', meta.name)}
                    type={inputType}
                    name={meta.name}
                    displayName={meta.displayName}
                    value={inputValue}
                    readOnly={meta.readOnly}
                    onChange={e => this.inputValueChanged(e)}
                >
                </InputBase>
                <Comment meta={meta}></Comment>

            </div>
        );
    }

    getInputType(inputType: any, meta: any):string {
        return meta.component || meta.type || inputType;
    }
}
