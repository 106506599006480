import { IMeta } from '../../index/IndexModel';
import { ValueChanged } from '../Types';
import React = require('react');

interface IProps {
    items: any[];
    valueChangedAction: ValueChanged;
    meta: IMeta;
    classNames?: string;
}

interface IState{
    readOnly:boolean;
    items:any[];
}

export default class MultiCheckBoxes extends React.Component<IProps, IState> {
    constructor(prop:IProps) {
        super(prop);
        this.state = {
            items: this.props.items,
            readOnly: prop.meta.readOnly
        };
    }

    checkboxClick = (event:any, value:any) => {
        if (this.state.readOnly) { return; }
        const isChecked = event.target.checked;
        const items = this.state.items;

        items.forEach(item => {
            if (item.Value === value) { item.Selected = isChecked; }
        });

        this.setState({ items: items });

        const values = items.filter(i => i.Selected).map(i => i.Value);
        if (this.props.valueChangedAction) {
            this.props.valueChangedAction(this.props.meta.name, values);
        }
    }

    render() {
        const options = this.state.items;
        return (
            <div>
                <div className="data-label">
                    {this.props.meta.displayName}
                </div>

                <div className="data-input" >
                    {options.map(i =>
                        (<React.Fragment>
                            <input readOnly={this.state.readOnly} type="checkbox" key={i.Value} name={i.Value} onChange={(event) => this.checkboxClick(event, i.Value)} checked={i.Selected} ></input>
                            <span>{i.DisplayValue}</span><br></br>
                        </React.Fragment>))
                    }

                </div>
            </div>
        );
    }
}
