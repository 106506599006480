import TabEdit, { ITabProps, ITabState } from '../framework/framework/edit/TabEdit';
import Button from '../framework/framework/components/button/Button';
import { ModalService } from '../framework/framework/components/modal/ModalService';
import axios from 'axios';
import Index from '../index/Index';
import React = require('react');

export class FilesTab extends TabEdit<ITabProps, ITabState> {
    protected renderTabHeader(): React.ReactNode {
        return <>
            <Button
                title={'Dodaj'}
                disabled={false}
                action={() => this.onAddFile()} />
        </>;
    }

    modalService = ModalService.Instance();

    protected onItemClick(item: any): void {
        console.log(item);
        // window.location.href = this.buildUrl(`message/edit/${item.id}`)
    }

    onAddFile(): void {
        this.modalService.Open(() =>
            <>
                <Index
                    indexOption={{ modal: true, multiSelect: true, onClose: (selected) => this.onSelectedClick(selected) }}
                    controller={'Files'}
                    appCtx={this.props.appCtx}
                    // filterVM={ { model: { imagesOnly: true } }}

                />
            </>

        );
    }

    onSelectedClick(selected: number[]): void {
        this.modalService.Close();
        if (selected.length === 0) { return; }
        const url = this.props.appCtx.buildUrl('product/addFiles');
        const model = { selected: selected };

        axios.post(url, model)
            .then(result => {
                this.addItems(result.data as any[]);
            })
            .catch(error => {
                console.log(error);
            }
            );
    }

    addItems(items: any[]) {
        this.props.onCollectionAdd(this.props.viewModel.name, items);
    }

    onCancelClick() {
        this.modalService.Close();
    }
}
