
import { IMeta } from '../../index/IndexModel';
import { ValueChanged } from '../Types';
import classes from '../../tools/classes';
import * as style from '../../../../styledComponent/style.css';
import React = require('react');

interface IProps {
    items: any[];
    valueChangedAction: ValueChanged;
    meta: IMeta;
    value: any[];
    classNames?: string;
    withLabel: boolean;
}

export default class MultiSelect extends React.Component<IProps, {}> {
    valueChanged = (event: any) => {
        if (this.props.valueChangedAction) {
            this.props.valueChangedAction(this.props.meta.name, event.target.value);
        }
    }

    render() {
        const classNames = classes(style.dataInput, style.singleSelect, style.multiSelect);
        const options = this.props.items;
        const selected = this.props.value.map(i => i.toString());
        return (
            <div className={classNames}>
                {this.props.withLabel && <div className={style.dataLabel}>
                    {this.props.meta.displayName}
                </div>}
                <select className="data-input" value={selected} onChange={e => this.valueChanged(e)} multiple>
                    {options.map(i => <option key={i.value} value={i.value} >{i.displayValue}</option>)}

                </select>

            </div>
        );
    }
}
