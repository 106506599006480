import axios from 'axios';
import { ApplicationContext } from '../framework/framework/shared/ApplicationContext';
import * as style from './style.css';

import FileUploader from '../framework/framework/components/fileUploader/FileUploader';
import React = require('react');

interface IImportModel {
    name: string;
    label: string;
}
interface IProps {
    controller: string;
    appCtx: ApplicationContext;
}

interface IState {
    file: any;
    importers: IImportModel[];
    importedMessage: string;
}

export default class ImportComponent extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            file: null,
            importers: [],
            importedMessage: ''
        };
    }

    axios = axios;

    componentDidMount() {
        const url = this.props.appCtx.buildUrl(`${this.props.controller}/Importers`);
        axios.get(url)
            .then(response => {
                const data = response.data;
                this.setState({ importers: data });
            }).catch(error => {
                debugger;
            });
    }

    render() {
        return (
            <div className={style.importContainer} >

                <FileUploader
                    onFilesAdded={(arg) => this.onFileAdded(arg)}
                    multiple={false}
                >
                    <div className={style.fileList}>
                        {this.state.file && <span >{this.state.file.name}</span>}
                    </div>
                </FileUploader>
                <div className={style.header}>
                    Import
                </div>
                {this.state.importedMessage && <div className={style.success}>
                    {this.state.importedMessage}
                </div>}
                <div className={style.actions}>
                    {this.state.importers.map(importer =>
                        <div key={importer.name} className={style.action} onClick={() => this.onSaveClick(importer.name)}>
                            {importer.label}
                        </div>
                    )}

                </div>
            </div>
        );
    }

    onSaveClick(name: string): void {
        if(!this.state.file)
        return;
        const uri = this.props.appCtx.buildUrl(this.props.controller + '/Upload');
        const formData = new FormData();
        formData.append('file', this.state.file);
        formData.append('name', name);


        this.axios.post(uri, formData).then(response => {
            const fileName = this.state.file.name;
            this.setState({ importedMessage: `Plik ${fileName} został zaimportowany` });
            this.setState({ file: null });
        }).catch(error => {
            console.log(error);
        });
    }

    onFileAdded(arg: any[]): void {
        const file = arg[0];
        this.setState({ file: file });
    }
}
