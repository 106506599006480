// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-framework-framework-components-fileUploader-style__dropZone--3TddZ{\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    padding: 20px;\r\n    background: #ececec;\r\n    border-radius: 5px;\r\n    cursor: pointer;\r\n}\r\n\r\n.src-framework-framework-components-fileUploader-style__highlight--25qou{\r\nbackground: #acacac;\r\n\r\n}", "",{"version":3,"sources":["webpack://src/framework/framework/components/fileUploader/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;AACA,mBAAmB;;AAEnB","sourcesContent":[".dropZone{\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    padding: 20px;\r\n    background: #ececec;\r\n    border-radius: 5px;\r\n    cursor: pointer;\r\n}\r\n\r\n.highlight{\r\nbackground: #acacac;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropZone": "src-framework-framework-components-fileUploader-style__dropZone--3TddZ",
	"highlight": "src-framework-framework-components-fileUploader-style__highlight--25qou"
};
export default ___CSS_LOADER_EXPORT___;
