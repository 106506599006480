import ComponentFactory from '../../components/basic/ComponentFactory';
import ButtonLink from '../../components/button/ButtonLink';
import { IButtonModel } from '../../components/button/IButtonModel';
import { ActionT } from '../../components/Types';
import parseUrlParam from '../../tools/parseUrlParam';
import { IIndexColumn, IMeta } from '../IndexModel';
import * as style from '../../../../styledComponent/style.css';
import CheckBox from '../../components/basic/CheckBox';
import React = require('react');

export interface IRowProps {
    rowData: any;
    rowButtons: IButtonModel[];
    selectable: boolean;
    columns: IIndexColumn[];
    editUrl: string;
    onSelectClick: ActionT<number>;
    isSelected: boolean;
}
interface IState { }

export class ResultDefaultRow<IProps extends IRowProps> extends React.Component<IProps, IState> {
    render() {
        const rowData = this.props.rowData;
        const key = rowData.id;
        console.log(this.props.columns);
        return (<tr key={key} id={key} onClick={(e) => this.onDataRowClick(e, rowData)} >
            {this.props.selectable && this.getSelectCell(rowData)}
            {this.props.columns.filter(c => c.meta.visible).map(c => this.renderCell(rowData, c))}
            {!this.props.selectable && this.renderButtonsCell(this.props.rowButtons, rowData)}
        </tr>);
    }

    protected onDataRowClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, rowData: any) {
        if (this.props.selectable) {
            this.props.onSelectClick(rowData.id);
            return;
        }
        const id = rowData.id;
        window.location.href = this.props.editUrl.replace('{id}', `${id}`);
    }

    protected getSelectCell(row: any) {
        return <td key={'select id'} onClick={(event) => this.onSelectClick(event, row.id)} >
            <CheckBox
                meta={{} as IMeta}
                valueObject={{ value: this.props.isSelected, key: 'select' }}
                valueChangedAction={null}
                withLabel={false}
                classNames={''}
            ></CheckBox>

        </td>;
    }

    onSelectClick(event: any, id: number) {
        event.stopPropagation();
        this.props.onSelectClick(id);
    }

    renderCell(row: any, c: IIndexColumn): any {
        const value = this.getCellValue(row, c);

        return <td key={c.slug + row.id} >
            {value}
        </td>;
    }

    protected getCellValue(row: any, c: IIndexColumn) {
        if (c.meta.component == null) {
            return row[c.name];
        }

        const component = ComponentFactory.GetRawComponent(c,
            row[c.name],
            {
                key: c.name,
                onModelChange: () => {},
                onModelClick: null
            });
        return component || row[c.name];
    }

    protected renderButtonsCell(rowButtons: IButtonModel[], rowData: any): React.ReactNode {
        if (rowButtons.length === 0) { return null; }
        return <td>
            {ResultDefaultRow.renderButtons(rowButtons, rowData)}
        </td>;
    }

    public static renderButtons(rowButtons: IButtonModel[], rowData: any): React.ReactNode {
        return <div className={style.actionSection}>
            {rowButtons.map(b => {
                const url = parseUrlParam(b.url, rowData);
                return <ButtonLink key={b.title} url={url}
                    title={b.title}
                    disabled={false}
                />;
            }

            )}
        </div>;
    }
}
