// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-login-style__loginContainer--1HjSZ{\r\n    margin-left: 100px;\r\n    margin-top: 100px; \r\n}\r\n.src-login-style__error--3L48B{\r\n    color:red;\r\n    margin-top: 30px;\r\n\r\n}", "",{"version":3,"sources":["webpack://src/login/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,SAAS;IACT,gBAAgB;;AAEpB","sourcesContent":[".loginContainer{\r\n    margin-left: 100px;\r\n    margin-top: 100px; \r\n}\r\n.error{\r\n    color:red;\r\n    margin-top: 30px;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginContainer": "src-login-style__loginContainer--1HjSZ",
	"error": "src-login-style__error--3L48B"
};
export default ___CSS_LOADER_EXPORT___;
