import { Action } from '../../components/Types';
import React = require('react');

export interface IProps{
    color?: string;
    onClick?: Action;
}
export interface IState {}

export class BaseIcon extends React.Component<IProps, IState> {
    render() {
        return (
            <span className="material-icons" onClick={() => this.props.onClick && this.props.onClick()}>
                {this.name()}
            </span>
        );
    }

    protected name() {
        return '';
    }
}
