import IndexComponent, { IIndexProps, IIndexState } from '../framework/framework/index/IndexComponent';

interface IProps extends IIndexProps{

}

interface IState extends IIndexState{

}

export default class ParametersIndex extends IndexComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            results: [],
            columns: [],
            filterVM: null,
            // paging: this.props.indexData.Paging,
            timerValue: null,
            indexButtons: [],
            indexBatchButtonsCollection: [],
            rowButtons: [],
            orderModel: null,
            paging: null,
            editUrl: '',
            deleteUrl: '',
            createUrl: '',
            selectedIds: [],
            breadcrumbs: []
        };
    }
}
