import axios from 'axios';
import { ApplicationContext } from '../framework/framework/shared/ApplicationContext';
import * as style from './style.css';
import { IEditModelProperty } from '../framework/framework/index/IndexModel';
import ComponentFactory from '../framework/framework/components/basic/ComponentFactory';
import Button from '../framework/framework/components/button/Button';
import React = require('react');

interface IProps {
    controller: string;
    appCtx: ApplicationContext;
}

interface IState {
    editModel: any;
    properties: IEditModelProperty[];
    error:string;
}

export default class LoginComponent extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            editModel: null,
            properties: [],
            error: null
        };
    }

    componentDidMount() {
        const url = this.props.appCtx.buildUrl(`${this.props.controller}/GetLoginModel`);
        axios.get(url)
            .then(response => {
                const data = response.data;
                this.setState({
                    editModel: data.model,
                    properties: data.properties
                });
            }).catch(error => {
                debugger;
            });
    }

    render() {
        return (
            <div className={style.loginContainer} onKeyDown={(e) => this.onKeyDown(e)}>
                {this.state.properties.map(p => {
                    return ComponentFactory.GetComponent(p, this.editModelValue(p.meta.name), {
                        onModelChange: (key: string, value: any) => this.onModelChange(key, value),

                        key: p.meta.name
                    });
                })}

                <Button
                    disabled={false}
                    action={() => this.onLoginClick()}
                    title={'Login'}
                />
                {this.state.error && <div className={style.error} >{this.state.error}</div>}
            </div>
        );
    }

    onKeyDown(e: React.KeyboardEvent<HTMLDivElement>): void {
        if (e.key === 'Enter') { this.onLoginClick(); }
    }

    onLoginClick(): void {
        const url = this.props.appCtx.buildUrl(`${this.props.controller}/Login`);
        const data = this.state.editModel;
        axios.post(url, data)
            .then(response => {
                window.location.href = response.data.redirectUrl;
            })
            .catch(error => {
                this.onError(error);
            });
    }

    onError(error: any) {
        if (error.response.status == 404) {
            if (error.response.data && error.response.data.redirectUrl) {
                const url = this.props.appCtx.buildUrl(error.response.data.redirectUrl);
                window.location.href = url;
            } else {
                this.setState({ error: error.response.data.message || 'Not found' });
            }
        }
    }

    onModelChange(key: string, value: any): void {
        const model = this.state.editModel;
        model[key] = value;
        this.setState({ editModel: model });
    }

    editModelValue(name: string): any {
        return this.state.editModel[name];
    }
}
