import TabEdit, { ITabProps, ITabState } from '../framework/framework/edit/TabEdit';
import Button from '../framework/framework/components/button/Button';
import { ModalService } from '../framework/framework/components/modal/ModalService';
import axios from 'axios';
import { IEditModelProperty } from '../framework/framework/index/IndexModel';
import { IParameterValue, ParameterType } from './parameters/IParameterValue';
import { ParameterComponentFactory } from './ParameterComponentFactory';
import React = require('react');

export class DescriptionsTab extends TabEdit<ITabProps, ITabState> {
    protected renderTabHeader(): React.ReactNode {
        return <>
            <Button
                title={'Dodaj'}
                disabled={false}
                action={() => this.onAddDescription()} />
        </>;
    }

    renderCellContent(p: IEditModelProperty, index: number, item: any) {
        if (p.name === 'name') { return item.name; }

        if (p.name === 'value' || p.name === 'values') {
            const parameter = this.parameterComponent(p, item, index);
            return parameter;
        }
    }

    parameterComponent(p: IEditModelProperty, item: IParameterValue, index: number) {
        return ParameterComponentFactory.GetComponent(p, item, item => this.onParameterChanged(item));
    }

    onParameterChanged(item: IParameterValue) {
        const items = this.props.editModel as IParameterValue[];

        for (let i = 0; i < items.length; i++) {
            if (items[i].id === item.id) {
                if (item.type === ParameterType.Single) {
                    items[i].value = item.value;
                } else {
                    items[i].values = item.values;
                }
            }
        }

        this.onModelChangeInternal(items);
    }

    onParameterChange(key: string, value: any, item: any): void {
    }

    onAddDescription(): void {
        this.modalService.Close();
        const url = this.props.appCtx.buildUrl('product/AddDescription');

        axios.post(url, {})
            .then(result => {
                this.addItems(result.data as any[]);
            })
            .catch(error => {
                console.log(error);
            }
            );
    }

    onValueChange(name: string, value: any): void {
        this.onModelChangeInternal(value);
    }

    modalService = ModalService.Instance();

    protected onItemClick(item: any): void {
        console.log(item);
        // window.location.href = this.buildUrl(`message/edit/${item.id}`)
    }

    onSelectedClick(selected: number[]): void {

    }

    addItems(items: any[]) {
        this.props.onCollectionAdd(this.props.viewModel.name, items);
    }

    onCancelClick() {
        this.modalService.Close();
    }
}
