import Comment from './Comment';
import { IBaseProps, IBaseState } from './BaseComponent';
import { IMeta } from '../../index/IndexModel';
import classes from '../../tools/classes';
import * as style from '../../../../styledComponent/style.css';
import React = require('react');

interface IProps extends IBaseProps {
    meta: IMeta;
    classNames: string;

}

interface IState extends IBaseState {

}

export default class CheckBox extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    inputValueChanged(event: any) {
        const newValue = event.target.checked;

        if (this.props.valueChangedAction && !this.props.meta.readOnly) {
            this.props.valueChangedAction(this.props.meta.name, newValue);
        }
    }

    render() {
        const classNames = classes(style.dataInput, this.props.classNames || '');
        const meta = this.props.meta;
        const inputValue = this.props.valueObject.value || false;
        return (
            <div className={classNames} key={this.props.meta.name}>
                {this.props.withLabel && <div className={style.dataLabel}>
                    {meta.displayName}
                </div>}
                <div className={this.props.classNames }>
                    <input
                        type={'checkbox'}
                        key={meta.name}
                        name={meta.displayName}
                        step={'any'}
                        checked={inputValue}
                        readOnly={meta.readOnly}
                        onChange={(event) => this.inputValueChanged(event)}></input>
                </div>

                {/* <InputBase
                    classNames={classes(meta.readOnly ? style.readOnly : "", meta.name)}
                    type={this.props.inputType}
                    name={meta.name}
                    displayName={meta.displayName}
                    value={inputValue}
                    readOnly={meta.readOnly}
                    onChange={e => this.inputValueChanged(e)}
                >
                </InputBase> */}

                <Comment meta={meta}></Comment>

            </div>
        );
    }
}
