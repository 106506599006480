
import { IButtonModel } from '../../components/button/IButtonModel';
import { ActionT } from '../../components/Types';
import getUID from '../../tools/uid';
import { IIndexColumn } from '../IndexModel';
import classes from './../../tools/classes';
import * as style from './style.css';
import React = require('react');

export interface IProps {
    selectable: boolean;
    results: any[];
    columns: IIndexColumn[];
    rowButtons: IButtonModel[];
    editUrl: string;

    onSelectClick: ActionT<number>;
}

export interface IState {

}

export default class ResultTable extends React.Component<IProps, IState> {
    render() {
        return (<div className={classes(style.indexResultTable)}>

            {this.props.results.length > 0 ? this.renderTable() : this.noResults()}
        </div>);
    }

    getVisibleColumns() {
        return this.props.columns.filter(c => c.visible);
    }

    noResults(): React.ReactNode {
        return <div>no results</div>;
    }

    renderTable(): React.ReactNode {
        return <table>
            <thead>
                <tr key={getUID()}>
                    {this.props.selectable && <th key={'select column'}></th>}
                    {this.getVisibleColumns().map(c => <th key={c.slug}>{c.displayName}</th>)}
                    {!this.props.selectable && this.props.rowButtons.length > 0 && <th key={'actions'}></th>}
                </tr>
            </thead>
            <tbody>
                {this.props.children}
            </tbody>
        </table>;
    }
}
