import classes from '../framework/framework/tools/classes';
import * as style from './style.css';
import React = require('react');

interface IProps {
    uriBuilder: (uir: string) => string;
    userName: string;
    isLogged: boolean;
    role: string;
}

interface IState {
}

class MenuBar extends React.Component<IProps, IState> {
    render() {
        return (
            <div className={style.menuContainer}>
                <nav className={classes(style.menuBar, style.menu2)}>
                    <div className={style.brandName}>
                        <a href={this.props.uriBuilder('')}>
                            APC Prodent - Admin Panel
                        </a>
                    </div>
                    {this.props.isLogged &&
                        <>
                            <div className={style.navbarLinks}>
                                <ul>
                                    {this.createLink('companies', 'Firmy', true)}
                                    {this.createLink('products', 'Produkty')}
                                    {this.createLink('taxonomies', 'Taksonomia')}
                                    {this.createLink('files', 'Pliki')}
                                    {this.createLink('import', 'Import')}
                                </ul>
                            </div>
                            {this.isAdmin() &&
                                <div className={style.navbarLinks}>
                                    <div className={style.systemNavi} >System
                                        <ul >
                                            {this.createLink('users', 'Users')}
                                            {this.createLink('parameters', 'Parameter')}
                                            {this.createLink('parametergroups', 'Parameter groups')}
                                            {this.createLink('dictionaries', 'Dictionary')}
                                            {this.createLink('newses', 'News')}
                                            {this.createLink('promotions', 'Promotions')}
                                            {this.createLink('parcels', 'Przesyłki')}
                                        </ul>
                                    </div>
                                </div>
                            }
                            <div className={style.navbarLinks}>
                                {this.createLink('Account/Logout', `Wyloguj ${this.props.userName}`)}

                            </div>
                        </>
                    }
                </nav>
            </div>
        );
    }

    isAdmin() {
        return this.props.role === 'pimAdmin';
    }

    createLink(uri: string, label: string, adminOnly?: boolean): React.ReactNode {
        if (adminOnly === true && !this.isAdmin()) { return <></>; }

        return <li><a href={this.props.uriBuilder(uri)} >{label} </a></li>;
    }
}

export default MenuBar;
