import * as style from '../../../../styledComponent/style.css';
import classes from '../../tools/classes';
import { Action } from '../Types';
import React = require('react');

interface IProps {
    show: boolean;
    onClose: Action;
    closeButton?: string;
}
interface IState {

}

export default class Modal extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {

        };
    }

    render() {
        if (!this.props.show) { return <></>; }

        return <div className={style.modalWrapper} onClick={() => this.props.onClose()}>
            <div className={classes(style.modal, style.formWrapper)} onClick={(event) => event.stopPropagation()}>
                <div className={style.modalContent}>
                    {this.props.children}
                </div>
            </div>
        </div>;
    }
}
