import { ValueChanged } from '../Types';
import React = require('react');

export interface IBaseProps{
   valueObject: {value: any, key:string} ;
   valueChangedAction: ValueChanged;
   withLabel:boolean;

}

export interface IBaseState{

}

export default class BaseComponent< IBaseProps, IBaseState > extends React.Component<IBaseProps, IBaseState> {
    constructor(props:IBaseProps) {
        super(props);
    }
}
