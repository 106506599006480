import { IBreadcrumb } from '../shared/models/IBreadcrumb';
import * as style from '../../../styledComponent/style.css';
import React = require('react');

interface IProps {
    breadcrumbs: IBreadcrumb[];
}

interface IState {

}

export default class Breadcrumbs extends React.Component<IProps, IState> {
    render() {
        if (this.props.breadcrumbs.length === 0) { return <></>; }

        return <div className={style.breadcrumbs}>
            {this.props.breadcrumbs.map((b, index) =>
                <div key={index}>
                    {index > 0 && <span key={index} className={style.breadcrumbItem} >{'\u00BB'}</span>}
                    {b.url ? <a className={style.breadcrumbItem} href={b.url} >{b.title}</a> : <span className={style.breadcrumbItem} >{b.title}</span>}
                </div>
            )}
        </div>;
    }
}
