export default class DataChangedEventArg {
    id: any;
    name: string;
    value: any;

    constructor(id:any, name:string, value:any) {
        this.id = id;
        this.name = name;
        this.value = value;
    }
}
