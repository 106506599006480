import Application from './application/Application';
import DictionaryEdit from './dictionary/DictionaryEdit';
import FileIndex from './files/FileIndex';
import EditComponent from './framework/framework/edit/EditComponent';
import { ApplicationContext, IApplicationContext } from './framework/framework/shared/ApplicationContext';
import Index from './index/Index';
import LoginComponent from './login/LoginComponent';
import ProductEdit from './product/ProductEdit';
import TaxonomyEdit from './taxonomy/TaxonomyEdit';
import TaxonomyIndex from './taxonomyIndex/TaxonomyIndex';
import PromotionEdit from './promotion/PromotionEdit';
import ComponentFactory from './framework/framework/components/basic/ComponentFactory';
import CustomComponents from './shared/CustomComponents';
import React = require('react');
import ReactDOM = require('react-dom');
import ImportComponent from './import/ImportComponent';

const root = document.getElementById('app');

if (root !== undefined && root !== null) {
    let componentData = {} as any;
    // let component = <Start />;
    let component = <></>;
    if (root.dataset.componentData) { componentData = JSON.parse(root.dataset.componentData); }

    const appCtx = (window as any).applicationContext as IApplicationContext;
    const appCtxObject = new ApplicationContext(appCtx);

    if (componentData.component === 'index') {
        component = <Index controller={componentData.controller} appCtx={appCtxObject} />;
        ComponentFactory.RegisterFactory((property, value) => CustomComponents.getComponent(property, value));
    }

    if (componentData.component === 'taxonomyIndex') { component = <TaxonomyIndex controller={componentData.controller} appCtx={appCtxObject} />; }

    if (componentData.component === 'fileIndex') { component = <FileIndex controller={componentData.controller} appCtx={appCtxObject} />; }

    if (componentData.component === 'productEdit') {
        const id = parseInt(root.dataset.entity);
        component = <ProductEdit controller={componentData.controller} appCtx={appCtxObject} id={id} />;
    }
    if (componentData.component === 'promotionEdit') {
        const id = parseInt(root.dataset.entity);
        component = <PromotionEdit controller={componentData.controller} appCtx={appCtxObject} id={id} />;
    }
    if (componentData.component === 'taxonomyEdit') {
        const id = parseInt(root.dataset.entity);
        component = <TaxonomyEdit controller={componentData.controller} appCtx={appCtxObject} id={id} />;
    }
    if (componentData.component === 'dictionaryEdit') {
        const id = parseInt(root.dataset.entity);
        component = <DictionaryEdit controller={componentData.controller} appCtx={appCtxObject} id={id} />;
    }

    if (componentData.component === 'edit') {
        const id = parseInt(root.dataset.entity);
        component = <EditComponent controller={componentData.controller} appCtx={appCtxObject} id={id} />;
    }
    if (componentData.component === 'loginComponent') {
        component = <LoginComponent controller={componentData.controller} appCtx={appCtxObject} />;
    }
    if (componentData.component === 'importComponent') {
        component = <ImportComponent controller={componentData.controller} appCtx={appCtxObject} />;
    }

    ReactDOM.render(<Application appCtx={appCtxObject}>
        {component}
    </Application>, root);
}
