
import ComponentFactory from '../../components/basic/ComponentFactory';
import { IndexFilterVM, IndexFilterVMProperty } from '../IndexModel';
import * as style from '../../../../styledComponent/style.css';
import classes from '../../tools/classes';
import React = require('react');

interface IProps {
    indexFilterChanged: any;
    filterVM: IndexFilterVM;
}

interface IState {

}

export default class IndexFilterPanel extends React.Component<IProps, IState> {
    handleChange(filterName, filterValue) {
        if (this.props.indexFilterChanged !== undefined) {
            this.props.indexFilterChanged(filterName, filterValue);
        }
    }

    renderFilterField(filterProperty: IndexFilterVMProperty) {
        const value = this.props.filterVM.model[filterProperty.meta.name] || null;
        const component = ComponentFactory.GetComponent(filterProperty, value, {
            key: '',
            onModelChange: (n, v) => this.handleChange(n, v)
        });
        return component;
    }

    render() {
        const filters = this.props.filterVM.properties || [];
        return (
            <div className={classes(style.filterContainer, style.container, style.withBorder)}>
                {
                    filters.filter(f => f.meta.visible).map(f => { return this.renderFilterField(f); })
                }
            </div>
        );
    }
}
