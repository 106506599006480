import * as style from '../../../../styledComponent/style.css';
import React = require('react');

interface IProps {
    disabled: boolean;
    url: string;
    title: string;
}

interface IState { }

export default class ButtonLink extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
    }

    render() {
        return (
            <a href={this.props.url}>
                <button disabled={this.props.disabled} className={style.editButton} type="button" >{this.props.title}</button>
            </a>
        );
    }
}
