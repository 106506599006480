
export interface IParameterValue {
    id: number;
    name: string;
    parameterId: number;
    type: ParameterType;
    valueType: ParameterValueType;
    typeName: string;
    value?: any;
    values?: any[];

}

export enum ParameterType{
    Single,
    Range,
    Multiple
}

export enum ParameterValueType{
    Numeric,
    Text,
    Dictionary
}
