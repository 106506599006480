import React = require("react");
import EditComponent, { IEditProps, IEditState } from "../framework/framework/edit/EditComponent";
import { IEditModelProperty } from "../framework/framework/index/IndexModel";
import {  ValuesTab } from "./ValuesTab";

interface IProps  extends IEditProps{

}

interface IState  extends IEditState{
    
}

export default class  DictionaryEdit extends EditComponent<IProps , IState>{
    constructor(props: IProps){
        super(props);

    }
   
    protected getTabEditComponent(viewProperty: IEditModelProperty, index:number) {
        if(viewProperty.name==="values")
        return <ValuesTab
        key={index}
        onModelChange={(k: string, v: any) => this.saveToStore(k, v)}
        editModel={this.state.editModel[viewProperty.name]}
        viewModel={viewProperty}
        appCtx={this.props.appCtx}
        modelId={this.props.id}  
        onCollectionAdd={(key: string, items: any) => this.addToCollection(key, items)}      
        />
      
        return super.getTabEditComponent(viewProperty, index);
    }

}


