import CheckBox from '../framework/framework/components/basic/CheckBox';
import IndexComponent, { IIndexProps, IIndexState } from '../framework/framework/index/IndexComponent';
import { IMeta } from '../framework/framework/index/IndexModel';
import { ResultDefaultRow } from '../framework/framework/index/resultTable/ResultDefaultRow';

import * as style from './style.css';
import React = require('react');

interface IProps extends IIndexProps {

}

interface IState extends IIndexState {

}

export default class TaxonomyIndex extends IndexComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            results: [],
            columns: [],
            filterVM: null,
            // paging: this.props.indexData.Paging,
            timerValue: null,
            indexButtons: [],
            indexBatchButtonsCollection:[],
            rowButtons: [],
            paging: null,
            orderModel: null,
            editUrl: '',
            deleteUrl: '',
            createUrl: '',
            selectedIds: [],
            breadcrumbs: []
        };
    }

    protected getRowComponent(row:any) {
        return this.getDataRow(row, 0);
    }

    protected getSelectCell(row: any) {
        return <td key={'select id'} onClick={(event) => {
            event.stopPropagation();
            this.onSelectClick(row.id);
        }
        } >
            <CheckBox
                meta={{} as IMeta}
                valueObject={{ value: this.isSelected(row.id), key: 'select' }}
                valueChangedAction={null}
                withLabel={false}
                classNames={''}
            ></CheckBox>

        </td>;
    }

    isRowSelected(id: number) {
        return this.state.selectedIds.some(x => x === id);
    }

    getDataRow(row: any, level: number) {
        return (
            <>
                <tr key={row.id} onMouseDown={e => this.onTaxonomyRowDown(e, row.id)} >
                    {this.isModal() && this.getSelectCell(row)}
                    <td>
                        <div className={style.name}>
                            {level > 0 && this.getTabs(level)
                            }
                            {this.hasRowChildren(row) &&
                                <>
                                    <div className={style.arrow} onMouseDown={(event) => this.onTaxonomyClick(event, row)}>
                                        {row.expanded === true ? '- ' : '+ '}
                                    </div>
                                </>
                            }
                            {row.name}
                        </div>
                    </td>
                    <td>{row.count}</td>
                    <td>{ResultDefaultRow.renderButtons(this.state.rowButtons, row)}</td>
                </tr>
                {this.hasRowChildren(row) && row.expanded === true && row.children.map(r => this.getDataRow(r, level + 1))}
            </>
        );
    }

    onTaxonomyRowDown(e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, id: any): void {
        if (this.isModal()) { return; }
        const link = this.state.editUrl.replace('{id}', `${id}`);
        if (e.button === 0) { window.location.href = link; }
        if (e.button === 1) { window.open(link, '_blank'); } // to open new page
    }

    getTabs(level: number): React.ReactNode {
        const array = [];
        for (let i = 0; i < level; i++) {
            array.push(i);
        }
        return array.map((a, index) => <div key={index} className={style.tab}></div>);
    }

    hasRowChildren(row: any) {
        return row.count > 0;
    }

    onTaxonomyClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>, row: any): void {
        event.stopPropagation();
        const id = row.id;
        if (row.expanded === true) {
            this.setChildren(id, [], false);
            return;
        }

        const url = this.props.appCtx.buildUrl(`${this.props.controller}/getChildren/${id}`);
        this.axios.get(url)
            .then(result => {
                this.setChildren(row.id, result.data, true);
            })
            .catch(error => {
                console.log(error);
            });
    }

    setChildren(id: number, data: any, expanded: boolean) {
        const rows = this.state.results;
        this.setChildrenInternal(id, data, expanded, rows);

        this.setState({ results: rows });
    }

    setChildrenInternal(id: number, data: any, expanded: boolean, rows: any[]): void {
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].id === id) {
                rows[i].children = data;
                rows[i].expanded = expanded;
                return;
            }
            if (rows[i].children && rows[i].children.length > 0) { this.setChildrenInternal(id, data, expanded, rows[i].children); }
        }
    }
}
