
import EditRow from './EditRow';
import DataChangedEventArg from './DataChangedEventArg';
import { IMeta } from '../../index/IndexModel';
import { ValueChanged } from '../Types';
import React = require('react');

interface IProps{
    items:any[];
    valueChangedAction: ValueChanged;
    meta: IMeta;
    property: any;
    classNames: string;
}

interface IState{
    viewProperties:any;
    items:any[];
}

export default class CollectionEditComponent extends React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);

        this.state = {
            items: props.items,
            viewProperties: props.property.ViewModelProperty.Properties
        };
    }

    getFilteredViewProperties = () => {
        return this.state.viewProperties.map(p => p.Meta).filter(m => m.Visible == true);
    }

    getItem = (item:any) => {
        const properties = this.getFilteredViewProperties();
        return (
            <li className="data-input collection-row">
                {properties.map((p:any) => <div className={'column ' + p.Name}>
                    {item[p.Name]}
                </div>)}
            </li>
        );
    }

    valueChangedAction = (eventArg:any) => {
        if (eventArg.constructor.name !== 'DataChangedEventArg') { return; }

        const items = this.state.items;
        const item = items.filter(f => f.Id == eventArg.id)[0];
        if (item === undefined) {
            return;
        }
        item[eventArg.name] = eventArg.value;
        this.setState({ items: items });

        this.props.valueChangedAction(this.props.meta.name, items);
    }

    renderHeader = () => {
        return (
            <li className={'data-input ' + 'collection-header collection-row'}>
                {this.getFilteredViewProperties().map((p:any) =>
                    <div className={'column ' + p.Name}>
                        {p.DisplayName}
                    </div>)}

            </li>
        );
    }

    renderRows = () => {
        debugger;
        return (
            <React.Fragment>
                {this.state.items.map(i => {
                    return (

                        <li className={'data-input ' + ' collection-row edit'}>
                            {<EditRow key={i.Id} item={i} viewProperties={this.state.viewProperties} valueChangedAction={this.valueChangedAction}></EditRow>}
                        </li>);
                })
                }
            </React.Fragment>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.props.children}
                <div className={'data-input ' + this.props.meta.name}>
                    <ul>
                        {this.renderHeader()}
                        {this.renderRows()}

                    </ul>
                </div>
            </React.Fragment>
        );
    }
}
