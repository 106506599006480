
import Button from '../components/button/Button';
import { IButtonModel } from '../components/button/IButtonModel';
import { ActionT } from '../components/Types';
import React = require('react');

interface IProps {
    buttons: IButtonModel[];
    onButtonClick: ActionT<IButtonModel>;
}

interface IState { }

export default class IndexBatchButtons extends React.Component<IProps, IState> {
    onButtonClick(button: IButtonModel) {
        if (this.props.onButtonClick) {
            this.props.onButtonClick(button);
        }
    }

    render() {
        return (<div className="index-batch-buttons">
            {this.props.buttons.map(b =>
                <Button key={b.slug} disabled={false} action={() => this.onButtonClick(b)} title={b.title}></Button>
            )}
        </div>);
    }
}
