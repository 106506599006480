import { IEditModelProperty } from '../framework/framework/index/IndexModel';
import { IParameterValue, ParameterType, ParameterValueType } from './parameters/IParameterValue';
import NumericSingleParameter from './parameters/NumericSingleParameter';
import DictionarySingleParameter from './parameters/DictionarySingleParameter';
import DictionaryMultiParameter from './parameters/DictionaryMultiParameter';
import NumericMultiParameter from './parameters/NumericMultiParameter';
import NumericRangeParameter from './parameters/NumericRangeParameter';
import TextMultiParameter from './parameters/TextMultiParameter';
import TextSingleParameter from './parameters/TextSingleParameter';
import React = require('react');

export class ParameterComponentFactory {
    static GetComponent(p: IEditModelProperty, item: IParameterValue, onParameterChanged: (item: any) => void) {
        if (item.valueType === ParameterValueType.Dictionary && item.type === ParameterType.Single) {
            return <DictionarySingleParameter
                property={p}
                value={item}
                onChange={item => onParameterChanged(item)} />;
        }
        if (item.valueType === ParameterValueType.Dictionary && item.type === ParameterType.Multiple) {
            return <DictionaryMultiParameter
                property={p}
                value={item}
                onChange={item => onParameterChanged(item)} />;
        }

        if (item.valueType === ParameterValueType.Numeric && item.type === ParameterType.Single) {
            return <NumericSingleParameter
                property={p}
                value={item}
                onChange={item => onParameterChanged(item)} />;
        }

        if (item.valueType === ParameterValueType.Numeric && item.type === ParameterType.Range) {
            return <NumericRangeParameter
                property={p}
                value={item}
                onChange={item => onParameterChanged(item)} />;
        }
        if (item.valueType === ParameterValueType.Numeric && item.type === ParameterType.Multiple) {
            return <NumericMultiParameter
                property={p}
                value={item}
                onChange={item => onParameterChanged(item)} />;
        }

        if (item.valueType === ParameterValueType.Text && item.type === ParameterType.Single) {
            return <TextSingleParameter
                property={p}
                value={item}
                onChange={item => onParameterChanged(item)} />;
        }

        if (item.valueType === ParameterValueType.Text && item.type === ParameterType.Multiple) {
            return <TextMultiParameter
                property={p}
                value={item}
                onChange={item => onParameterChanged(item)} />;
        }
    }
}
