import ComponentFactory from '../framework/framework/components/basic/ComponentFactory';
import UriLink from '../framework/framework/components/basic/UriLink';
import { ModalService } from '../framework/framework/components/modal/ModalService';
import EditComponent, { IEditProps, IEditState } from '../framework/framework/edit/EditComponent';
import { IEditModelProperty } from '../framework/framework/index/IndexModel';
import TaxonomyIndex from '../taxonomyIndex/TaxonomyIndex';
import React = require('react');

interface IProps extends IEditProps {

}

interface IState extends IEditState {

}

export default class TaxonomyEdit extends EditComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.registerComponentFactory();
    }

    registerComponentFactory() {
        ComponentFactory.RegisterFactory((property: IEditModelProperty, value: any) => this.componentFactory(property, value));
    }

    componentFactory(property: IEditModelProperty, value) {
        if (property.meta.component === 'UrlLink') {
            return (
                <UriLink
                    onClick={() => this.onTaxonomyClick()}
                    property={property.meta}
                    value={value}
                    withLabel={true}
                />
            );
        }
    }

    modal = ModalService.Instance();
    onTaxonomyClick(): void {
        this.modal.Open(() =>
            <>
                <TaxonomyIndex
                    indexOption={{ modal: true, multiSelect: false, onClose: (selected) => this.onSelectedTaxonomyClick(selected) }}
                    controller={'Taxonomies'}
                    appCtx={this.props.appCtx}

                />
            </>

        );
    }

    onSelectedTaxonomyClick(selected: number[]): void {
        this.modal.Close();
        const taxonomyId = selected[0] || 0;
        const generalInfo = this.state.editModel.generalInfo;
        if (taxonomyId == 0) { return; }

        this.axios.get(this.buildUrl(`Taxonomy/TaxonomyModel/${taxonomyId}`)).then(result => {
            const data = result.data;

            generalInfo.parent = data.name;
            generalInfo.parentId = data.id;
            this.saveToStore('generalInfo', generalInfo);
        }

        ).catch(error => {
            debugger;
        }

        );
    }
}
