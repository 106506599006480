import IndexFilterPanel from './filter/IndexFilterPanel';
import IndexPaging from './paging/IndexPaging';
import IndexBatchButtons from './IndexBatchButtons';
import { IIndexColumn, IndexData, IndexFilterVM } from './IndexModel';
import { IndexOption } from './IndexOption';
import ResultTable from './resultTable/ResultTable';
import { IPagingModel } from './paging/IPagingModel';
import { IButtonModel } from './../components/button/IButtonModel';
import { ApplicationContext } from '../shared/ApplicationContext';
import axios from 'axios';
import * as React from 'react';
import Button from '../components/button/Button';
import * as style from '../../../styledComponent/style.css';
import { ResultDefaultRow } from './resultTable/ResultDefaultRow';
import { IButtonActionType } from '../components/button/IButtonActionType';
import Breadcrumbs from '../components/Breadcrumbs';
import { IBreadcrumb } from '../shared/models/IBreadcrumb';
import { IColumnOrder, IOrderModel } from './order/IOrderModel';

export interface IIndexProps {
    controller: string;
    appCtx: ApplicationContext;
    indexOption?: IndexOption;

}

export interface IIndexState {
    filterVM: IndexFilterVM;
    results: any[];
    columns: IIndexColumn[];
    timerValue: any;
    indexButtons: IButtonModel[];
    indexBatchButtonsCollection: IButtonModel[],
    rowButtons: IButtonModel[];
    paging: IPagingModel;
    orderModel: IOrderModel;
    editUrl: string;
    createUrl: string;
    deleteUrl: string;
    selectedIds: number[];
    breadcrumbs: IBreadcrumb[];
}

/*
 this.state = {
            results: [],
            columns: [],
            filterVM: null,
            //paging: this.props.indexData.Paging,
            timerValue: null,
            indexButtons: [],
            rowButtons: [],
            paging: null,
            editUrl: '',
            deleteUrl: '',
            createUrl: '',
            selectedIds: [],
        };
*/

export default class IndexComponent<IProps extends IIndexProps, IState extends IIndexState> extends React.Component<IProps, IState> {
    axios = axios;

    componentDidMount() {
        if (this.state.filterVM) {
            this.postFilter();
        } else {
            const url = this.props.appCtx.buildUrl(`${this.props.controller}/IndexData`);
            this.axios.get(url)
                .then(response => {
                    this.onIndexDataChanged(response.data);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    protected onIndexDataChanged(indexData: any) {
        const data = indexData as IndexData;
        this.setState({
            columns: data.columns,
            indexButtons: data.buttonsCollection,
            indexBatchButtonsCollection: data.indexBatchButtonsCollection,
            rowButtons: data.rowButtons,
            results: data.results,
            filterVM: data.filterViewModel,
            deleteUrl: data.deleteUrl,
            createUrl: data.createUrl,
            editUrl: data.editUrl,
            paging: data.paging,
            orderModel: data.order,
            breadcrumbs: data.breadcrumbs

        });
    }

    protected isModal() {
        if (this.props.indexOption === undefined) {
            return false;
        }

        return this.props.indexOption && this.props.indexOption.modal === true;
    }

    render() {
        return (
            <div className={style.indexForm}>
                {this.renderHeader()}
                {this.state.filterVM && <IndexFilterPanel
                    filterVM={this.state.filterVM}
                    indexFilterChanged={(k, v) => this.filterChanged(k, v)} />}
                {<IndexBatchButtons buttons={this.state.indexBatchButtonsCollection} onButtonClick={(b) => this.handleButtonClick(b)} />}
                {this.getResultTable()}
                {this.state.paging && <IndexPaging paging={this.state.paging} onPageClick={(arg: number) => this.onPagingClick(arg)} />}
            </div>
        );
    }

    protected renderHeader(): React.ReactElement {
        return (
            <>
                <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
                <div className={style.indexHeaderContainer}>
                    {this.headerButtons().map(b => b)}
                </div>
            </>
        );
    }

    protected getResultTable() {
        return <ResultTable
            results={this.state.results}
            selectable={this.isModal()}
            columns={this.state.columns}
            editUrl={this.state.editUrl}
            onSelectClick={(id) => this.onSelectClick(id)}
            rowButtons={this.state.rowButtons}
        >
            {this.state.results.map(rowData => this.getRowComponent(rowData))}

        </ResultTable>;
    }

    protected getRowComponent(row: any): any {
        return <ResultDefaultRow
            key={row.id}
            rowData={row}
            rowButtons={this.state.rowButtons}
            columns={this.state.columns}
            editUrl={this.state.editUrl}
            selectable={this.isModal()}
            onSelectClick={(id) => this.onSelectClick(id)}
            isSelected={this.isSelected(row.id)}
        ></ResultDefaultRow>;
    }

    protected headerButtons(): Button[] {
        const array = [];
        if (this.isModal()) {
            array.push(<Button
                disabled={false}
                action={() => this.props.indexOption.onClose(this.state.selectedIds)}
                title={'Wybierz'}
            />);
            array.push(<Button
                disabled={false}
                action={() => this.props.indexOption.onClose([])}
                title={'Zamknij'}
            />);
        } else {
            this.state.indexButtons.forEach(b => {
                array.push(<Button
                    disabled={false}
                    action={() => this.handleButtonClick(b)}
                    title={b.title}
                />);
            }
            );
        }
        return array;
    }

    handleButtonClick(button: IButtonModel): void {
        if (button.actionType === IButtonActionType.UrlLink) {
            window.location.href = button.url;
        }
        if (button.actionType === IButtonActionType.PostMethod) {
            const filter = this.state.filterVM.model;
            this.axios.post(button.url, filter)
                .then(response => {
                })
                .catch();
        }
        if (button.actionType === IButtonActionType.PostWithBlobResponse) {
            const filter = this.state.filterVM.model;
            this.axios.post(button.url, filter, { responseType: 'blob' })
                .then(response => {
                    const header = response.headers['content-disposition'];
                    const fileName = header.split('=')[1];
                    const blob = response.data;
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                })
                .catch();
        }
    }

    isSelected(id: number): boolean {
        return this.state.selectedIds.some(x => x === id);
    }

    protected onSelectClick(id: number) {
        let items = this.state.selectedIds;
        if (items.some(x => x === id)) {
            items = items.filter(x => x !== id);
        } else {
            if (this.props.indexOption.multiSelect === true) { items.push(id); } else { items = [id]; }
        }
        this.setState({ selectedIds: items });
    }

    onPagingClick(arg: number): void {
        const paging = this.state.paging;
        paging.pageNumber = arg;
        this.setState({ paging: paging });
        this.postFilter();
    }

    onSortChange(order:IColumnOrder[]):void {
        const o = this.state.orderModel || {} as IOrderModel;
        o.order = order;
        this.setState({ orderModel: o }, () => this.postFilter());
    }

    dataChanged(indexData) {
        // this.setState({ rows: indexData.Results });
        this.setState({ paging: indexData.Paging });
    }

    filterChanged(key, value): void {
        const filterState = this.state.filterVM;
        if (filterState === undefined) { return; }
        const model = filterState.model || {};
        model[key] = value;

        filterState.model = model;

        this.setState({ filterVM: filterState }, () => { this.postFilterDelay(); });
    }

    postFilterDelay() {
        let timer = this.state.timerValue || null;

        if (timer != null) {
            clearTimeout(timer);
        }

        timer = setTimeout(() => this.postFilter(), 3000);
        this.setState({ timerValue: timer });
    }

    protected postFilter = () => {
        const filter = this.state.filterVM.model;
        const paging = this.state.paging;
        const url = this.props.appCtx.buildUrl(`${this.props.controller}/IndexData`);
        const data = {
            filterModel: filter,
            pagingModel: paging,
            order: this.state.orderModel
        };
        this.axios.post(url, data)
            .then(response => {
                const data = response.data as IndexData;
                const messages = JSON.parse(response.headers.messages || '[]');
                const filterVM = this.state.filterVM;
                filterVM.model = data.filterModel;
                this.setState({
                    results: data.results,
                    filterVM: filterVM,
                    paging: data.paging,
                    orderModel: data.order
                }, () => this.updateResponseData(data, messages));
            })
            .catch();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected updateResponseData(_data: any, _messages: string[]) {

    }

    columnClick(colName) {
        alert('column ' + colName + ' has been clicked...');
    }
}
