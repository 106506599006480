import ComponentFactory from '../framework/framework/components/basic/ComponentFactory';
import UriLink from '../framework/framework/components/basic/UriLink';
import { ModalService } from '../framework/framework/components/modal/ModalService';
import EditComponent, { IEditProps, IEditState } from '../framework/framework/edit/EditComponent';
import { IEditModelProperty } from '../framework/framework/index/IndexModel';
import TaxonomyIndex from '../taxonomyIndex/TaxonomyIndex';
import { DescriptionsTab } from './DescriptionsTab';
import { ImagesTab } from './ImagesTab';
import { FilesTab } from './FilesTab';
import { ParametersTab } from './ParametersTab';
import { AccessoriesTab } from './AccessoriesTab';
import React = require('react');

interface IProps extends IEditProps {

}

interface IState extends IEditState {

}

export default class ProductEdit extends EditComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.registerComponentFactory();
    }

    registerComponentFactory() {
        ComponentFactory.RegisterFactory((property: IEditModelProperty, value: any) => this.componentFactory(property, value));
    }

    componentFactory(property: IEditModelProperty, value) {
        if (property.meta.component === 'UrlLink') {
            return (
                <UriLink
                    onClick={() => this.onTaxonomyClick()}
                    property={property.meta}
                    value={value}
                    withLabel={true}
                >

                </UriLink>

            );
        }
    }

    modal = ModalService.Instance();
    onTaxonomyClick(): void {
        this.modal.Open(() =>
            <>
                <TaxonomyIndex
                    indexOption={{ modal: true, multiSelect: false, onClose: (selected) => this.onSelectedTaxonomyClick(selected) }}
                    controller={'Taxonomies'}
                    appCtx={this.props.appCtx}
                />
            </>
        );
    }

    onSelectedTaxonomyClick(selected: number[]): void {
        this.modal.Close();
        const taxonomyId = selected[0] || 0;
        const generalInfo = this.state.editModel.generalInfo;
        if (taxonomyId == 0) { return; }

        this.axios.get(this.buildUrl(`Taxonomy/TaxonomyModel/${taxonomyId}`)).then(result => {
            const data = result.data;

            generalInfo.taxonomy = data.name;
            generalInfo.taxonomyId = data.id;
            this.saveToStore('generalInfo', generalInfo);
        }

        ).catch(error => {
            debugger;
        }

        );
    }

    protected getTabEditComponent(viewProperty: IEditModelProperty, index: number) {
        
        if (viewProperty.name === 'accessories') {
            return <AccessoriesTab
                key={index}
                onModelChange={(k: string, v: any) => this.saveToStore(k, v)}
                editModel={this.state.editModel[viewProperty.name]}
                viewModel={viewProperty}
                appCtx={this.props.appCtx}
                modelId={this.props.id}
                onCollectionAdd={(key: string, items: any) => this.addToCollection(key, items)}
            ></AccessoriesTab>;
        }
        if (viewProperty.name === 'images') {
            return <ImagesTab
                key={index}
                onModelChange={(k: string, v: any) => this.saveToStore(k, v)}
                editModel={this.state.editModel[viewProperty.name]}
                viewModel={viewProperty}
                appCtx={this.props.appCtx}
                modelId={this.props.id}
                onCollectionAdd={(key: string, items: any) => this.addToCollection(key, items)}
            ></ImagesTab>;
        }

        if (viewProperty.name === 'files') {
            return <FilesTab
                key={index}
                onModelChange={(k: string, v: any) => this.saveToStore(k, v)}
                editModel={this.state.editModel[viewProperty.name]}
                viewModel={viewProperty}
                appCtx={this.props.appCtx}
                modelId={this.props.id}
                onCollectionAdd={(key: string, items: any) => this.addToCollection(key, items)}
            ></FilesTab>;
        }

        if (viewProperty.name === 'parameters') {
            return <ParametersTab
                key={index}
                onModelChange={(k: string, v: any) => this.saveToStore(k, v)}
                editModel={this.state.editModel[viewProperty.name]}
                viewModel={viewProperty}
                appCtx={this.props.appCtx}
                modelId={this.props.id}
                onCollectionAdd={(key: string, items: any) => this.addToCollection(key, items)}
            ></ParametersTab>;
        }

        if (viewProperty.name === 'descriptions') {
            return <DescriptionsTab
                key={index}
                onModelChange={(k: string, v: any) => this.saveToStore(k, v)}
                editModel={this.state.editModel[viewProperty.name]}
                viewModel={viewProperty}
                appCtx={this.props.appCtx}
                modelId={this.props.id}
                onCollectionAdd={(key: string, items: any) => this.addToCollection(key, items)}
            />;
        }

        return super.getTabEditComponent(viewProperty, index);
    }
}
