import ComponentFactory from '../../framework/framework/components/basic/ComponentFactory';
import { ParameterBase } from './ParameterBase';
import React = require('react');

export default class DictionarySingleParameter extends ParameterBase {
    super() {
    }

    protected renderView(): JSX.Element {
        return <>
            {ComponentFactory.GetRawComponent(this.props.property, this.props.value.value,
                { onModelChange: (k, v) => this.onModelChange(k, v), key: this.props.value.parameterId.toString() })}

        </>;
    }

    onModelChange(k: string, v: any): void {
        const item = this.props.value;
        item.value = parseInt(v);
        this.props.onChange(item);
    }
}
