import InputBase from '../basic/InputBase';

// import CheckBoxBase from '../CheckBoxBase'
import DataChangedEventArg from './DataChangedEventArg';
import React = require('react');

interface IProps{
    item:any;
    valueChangedAction:(eventArg: any)=>void;
    viewProperties: any[];
}

interface IState{
    item:any;
}

export default class EditRow extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            item: props.item
        };
    }

    valueChanged = (eventArg) => {
        this.props.valueChangedAction(eventArg);
    }

    getComponent(rowItem, viewProperty, valueChangedFunc) {
        const meta = viewProperty.Meta;
        const inputValue = rowItem[meta.Name];
        return (<InputBase type={'text'}
            key={meta.Name}
            name={meta.DisplayName}
            value={inputValue}
            readOnly={meta.ReadOnly}
            onChange={(e) => valueChangedFunc(new DataChangedEventArg(rowItem.Id, meta.Name, e.target.value))}>
        </InputBase>
        );
    }

    getComponentCheckBox(rowItem, viewProperty, valueChangedFunc) {

        // let meta = viewProperty.Meta;
        // let inputValue = rowItem[meta.Name];
        // return (
        //     <CheckBoxBase
        //     key={meta.Name}
        //     name={meta.DisplayName}
        //     value={inputValue}
        //     readOnly={meta.ReadOnly}
        //     onChange={(e)=>valueChangedFunc(new DataChangedEventArg(rowItem.Id,meta.Name, e.target.checked))}
        //     onClick={valueChangedFunc}>

        //     </CheckBoxBase>

        // );
    }

    renderRow = (item) => {
        return (
            <React.Fragment>
                {this.props.viewProperties.filter(f => f.Meta.Visible).map(p => {
                    const functionName = 'getComponent' + (p.Meta.Component || '');
                    if (typeof (this[functionName]) === 'function') { return this[functionName](item, p, this.valueChanged); }
                    return 'row error';
                })}
            </React.Fragment>
        );
    }

    render() {
        return (<React.Fragment>
            {this.renderRow(this.state.item)}
        </React.Fragment>);
    }
}
