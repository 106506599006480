import React = require("react");
import { ParameterBase } from "./ParameterBase";


export default class NumericMultiParameter extends ParameterBase {
    super() {
    }

    protected renderView(): JSX.Element {
        return <>numeric multi</>;

    }
}


