import { IEditModelProperty, IMeta } from '../../index/IndexModel';
import { ActionT, FuncT3, ValueChanged } from '../Types';
import * as style from '../../../../styledComponent/style.css';
import classes from '../../tools/classes';
import ComponentFactory from './ComponentFactory';
import TrashIcon from '../../shared/icons/TrashIcon';
import { DeleteOutline } from '@material-ui/icons';
import HamburgerIcon from '../../shared/icons/HamburgerIcon';
import React = require('react');

interface IProps {
    items: any[];
    property: IEditModelProperty;
    meta: IMeta;
    valueChangedAction: ValueChanged;
    classNames: string;
    onItemClick: ActionT<any>;
    columns?:string[];
    cellValueRenderer?: any
    getViewModel?:FuncT3<any, any, number, IEditModelProperty[]>
}

interface IState {
    viewProperties: any[];
}

export default class CollectionComponent extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            viewProperties: props.property.viewModelProperty.properties
        };
    }

    getFilteredViewProperties = () => {
        if (this.props.columns) { return this.props.columns.map(c => { return { name: c, displayName: c }; }); }
        return this.props.property.viewModelProperty.properties.map(p => p.meta).filter(m => m.visible === true);
    }

    protected getViewModelProperties(viewModelProperty: any, item:any, index:number):IEditModelProperty[] {
        if (this.props.getViewModel) {
            return this.props.getViewModel(viewModelProperty, item, index);
        }

        return viewModelProperty.viewModels.filter(x => x.model.id === item.id)[0].properties as IEditModelProperty[];
    }

    getItem = (item: any, index: number) => {
        const spanStyle = { height: '3px', width: '100%', borderRadius: '10px', backgroundColor: '#c3c3c3' };

        const properties = this.getViewModelProperties(this.props.property.viewModelProperty as any, item, index);
        return (
            <tr className="data-input collection-row"
                key={index} onClick={() => this.props.onItemClick(item)}>

                <td className={'dragable'}
                    draggable={true}
                    key={'dragable'}
                    id={item.id}
                    onDrop={(event) => this.onDrop(event)}
                    onDragOver={(event => this.allowDrop(event))}
                    onDragStart={(event) => this.onDragStart(event, item.id)}>
                    <HamburgerIcon />
                </td>
                {properties.sort((a, b) => (a.meta.order || 0) - (b.meta.order || 0)).map(p => {
                    if (!p.meta.visible) { return <></>; }
                    const component = this.renderCellValue(p, index, item);

                    if (component == null) { return <></>; }

                    return <td className={'column ' + p.name} key={p.name}>
                        {component}
                    </td>;
                }
                )}
                <td key={'icons'} className={style.iconColumn} >
                    <div className={style.iconWrapper}>
                        <TrashIcon onClick={() => this.onRemoveClick(item)} />
                    </div>
                </td>
            </tr>
        );
    }

    renderCellValue(p: IEditModelProperty, index:number, item:any) {
        if (this.props.cellValueRenderer) { return this.props.cellValueRenderer(p, index, item); }

        const component = p.meta.component == null
            ? (p.model || '')
            : ComponentFactory.GetRawComponent(p, item[p.name],
                {
                    onModelChange: (k, v) => this.onItemChange(k, v, item),
                    onModelClick: (item) => this.onItemClick(item),
                    key: index.toString()
                });
        return component;
    }

    renderHeader = () => {
        return (
            <thead>
                <tr className={'data-input ' + 'collection-header collection-row'} >
                    <th className={'dragable'}>
                    </th>
                    {this.getFilteredViewProperties().map(p =>
                        <th className={'column ' + p.name} key={p.name}>
                            {p.displayName}
                        </th>)}
                    <th className={style.iconColumn}></th>
                </tr>
            </thead>
        );
    }

    onDragStart(event: React.DragEvent<HTMLTableDataCellElement>, id: any): void {
        event.dataTransfer.setData('Text', id.toString());
    }

    allowDrop(event: any): void {
        event.preventDefault();
    }

    onDrop(event: any): void {
        const source = parseInt(event.dataTransfer.getData('Text'));
        const target = parseInt((event.currentTarget as any).id);
        this.reorderItems(source, target);
    }

    reorderItems(source: number, target: number) {
        if (source === target) { return; }
        const sourceIndex = this.props.items.map(x => x.id).indexOf(source);
        const targetIndex = this.props.items.map(x => x.id).indexOf(target);
        const items = this.props.items.filter(x => x.id !== source);
        const sourceItem = this.props.items.filter(x => x.id === source)[0];
        const sortedItems = [];
        for (let i = 0; i < items.length; i++) {
            // push before target - drag to up
            if (sourceIndex > targetIndex && items[i].id === target) { sortedItems.push(sourceItem); }
            sortedItems.push(items[i]);
            // push after target - drag to down
            if (sourceIndex < targetIndex && items[i].id === target) { sortedItems.push(sourceItem); }
        }

        this.props.valueChangedAction(this.props.meta.name, sortedItems);
    }

    renderRows = () => {
        return (
            <tbody >
                {this.props.items.map((item, index) => this.getItem(item, index))}
            </tbody>
        );
    }

    render() {
        return (
            <>
                {this.props.children}
                <div className={classes(style.collectionData, this.props.meta.name)}>
                    <table >
                        {this.renderHeader()}
                        {this.renderRows()}

                    </table>
                </div>
            </>
        );
    }

    onItemClick(item: any): void {

    }

    onItemChange(k: string, v: any, item: any): void {
        const items = this.props.items;
        for (let i = 0; i < items.length; i++) {
            if (items[i].id == item.id) {
                items[i][k] = v;
            }
        }
        this.props.valueChangedAction(this.props.meta.name, items);
    }

    onRemoveClick(item: any) {
        debugger;
        const itemId = item.id;
        if (itemId === undefined) { console.log('no id value'); }
        const items = this.props.items.filter(x => x.id !== itemId);
        this.props.valueChangedAction(this.props.meta.name, items);
    }
}
