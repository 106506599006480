import IndexComponent, { IIndexProps, IIndexState } from '../framework/framework/index/IndexComponent';

interface IProps extends IIndexProps{
    filterVM?: any
}

interface IState extends IIndexState{

}

export default class Index extends IndexComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            results: [],
            columns: [],
            filterVM: this.props.filterVM,
            timerValue: null,
            indexButtons: [],
            indexBatchButtonsCollection:[],
            rowButtons: [],
            paging: null,
            orderModel: null,
            editUrl: '',
            deleteUrl: '',
            createUrl: '',
            selectedIds: [],
            breadcrumbs: []
        };
    }
}
