import Button from '../framework/framework/components/button/Button';
import FileUploader from '../framework/framework/components/fileUploader/FileUploader';
import IndexComponent, { IIndexProps, IIndexState } from '../framework/framework/index/IndexComponent';
import * as style from '../styledComponent/style.css';
import * as local from './style.css';
import React = require('react');

interface IProps extends IIndexProps {

}

interface IState extends IIndexState {
    files: any[];
}

export default class FileIndex extends IndexComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            results: [],
            columns: [],
            filterVM: null,
            timerValue: null,
            indexButtons: [],
            indexBatchButtonsCollection: [],
            rowButtons: [],
            paging: null,
            orderModel: null,
            editUrl: '',
            deleteUrl: '',
            createUrl: '',
            selectedIds: [],
            files: [],
            breadcrumbs: []

        };
    }

    protected renderHeader(): React.ReactElement {
        return (
            <div >

                <div className={style.indexHeaderContainer}>
                    {this.headerButtons().map(b => b)}
                </div>

                <FileUploader
                    onFilesAdded={(arg) => this.onFileAdded(arg)}
                    multiple={true}
                >
                    <div className={local.fileList}>
                        {this.state.files.map((f, index) => <span key={index}>{f.name}</span>)}

                        {this.state.files.length > 0 && <div style={{ width: '120px' }}>
                            <Button
                                disabled={false}
                                action={() => this.onSaveClick()}
                                title={'Zapisz'}
                            ></Button></div>}
                    </div>

                </FileUploader>
            </div>
        );
    }

    onSaveClick(): void {
        const uri = this.props.appCtx.buildUrl(this.props.controller + '/Upload');
        const formData = new FormData();
        for (let i = 0; i <= this.state.files.length; i++) {
            formData.append('files', this.state.files[i]);
        }

        this.axios.post(uri, formData).then(response => {
            this.setState({ files: [] });
            this.postFilter();
        }).catch(error => {
            console.log(error);
        });
    }

    onFileAdded(arg: any[]): void {
        const files = [...arg, ...this.state.files];
        this.setState({ files: files });
    }
}
