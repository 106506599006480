import { IMeta } from '../../index/IndexModel';
import classes from '../../tools/classes';
import { ValueChanged } from '../Types';
import BaseComponent, { IBaseProps } from './BaseComponent';
import * as style from '../../../../styledComponent/style.css';
import React = require('react');

interface IProps extends IBaseProps {
    property: IMeta;
    classNames: string;
}
interface IState {
    valueObject: any;
}

class TextArea extends BaseComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            // meta: this.props.property,
            valueObject: this.props.valueObject || {}
        };
    }

    inputValueChanged(event) {
        let newValue = event.target.value;

        if (this.props.valueChangedAction && !this.props.property.readOnly) {
            newValue = this.props.valueChangedAction(this.state.valueObject.key, newValue);
        }
        // let valueObj=this.state.valueObject;
        // valueObj.value=newValue;
        // this.setState({valueObject:valueObj});
    }

    render() {
        const meta = this.props.property;
        const classNames = classes(style.dataInput, this.props.classNames);
        return (

            <div className={classNames} key={this.props.property.name}>
                {this.props.withLabel &&
                    <div className={style.dataLabel}>
                        {meta.displayName}
                    </div>
                }
                <div className={classes((meta.readOnly ? style.readOnly : ' '), meta.name)}>
                    <textarea rows={5} cols={40} name={meta.displayName} readOnly={meta.readOnly} value={this.props.valueObject.value} onChange={e => this.inputValueChanged(e)}>
                    </textarea>
                </div>
            </div>

        );
    }
}

export default TextArea;
