import { IMeta } from '../../index/IndexModel';
import classes from '../../tools/classes';
import { ValueChanged } from '../Types';
import * as style from '../../../../styledComponent/style.css';
import React = require('react');

interface IProps {
    valueChangedAction: ValueChanged;
    property: IMeta;
    classNames?: string;
    valueObject: any;
}

interface IState {
    meta: IMeta
    valueObject: any;
}

class DateTime extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            meta: this.props.property,
            valueObject: this.props.valueObject || {}
        };
    }

    inputValueChanged(event: any) {
        const newValue = event.target.value;
        if (this.props.valueChangedAction && !this.state.meta.readOnly) {
            this.props.valueChangedAction(this.state.valueObject.key, newValue);
        }
    }

    render() {
        const classNames = classes(style.dataInput, this.props.classNames || '');
        const meta = this.state.meta;
        const inputValue = this.props.valueObject.value != null ? this.props.valueObject.value.substring(0, 10) : '';
        return (
            <div className={classNames}>
                <div className="data-label">
                    {meta.displayName}
                </div>
                <div className={(meta.readOnly ? 'readOnly ' : ' ') + meta.name + ' data '}>
                    <input type="date" name="{meta.DisplayName}" value={inputValue} min="1900-01-01" max="2100-12-31" readOnly={meta.readOnly} onChange={e => this.inputValueChanged(e)}></input>
                </div>
            </div>
        );
    }
}

export default DateTime;
