import { numberWithSpaces } from '../../framework/framework/tools/numberWithSpaces';
import React = require('react');

export function Decimal(value: number) {
    return <>
        <div style={{ textAlign: 'right' }}>
            {numberWithSpaces(value)}
        </div>
    </>;
}
