import * as style from '../../../../styledComponent/style.css';
import React = require('react');

interface IProps {
    classNames?: string;
    type: string;
    displayName?: string;
    name: string;
    value: string;
    readOnly: boolean;
    onChange: (event: any) => void;
}

export default class InputBase extends React.Component<IProps, {}> {
    constructor(props:IProps) {
        super(props);
    }

    render() {
        const inputConfig = this.getInputConfig();
        return (
            <div className={this.props.classNames}>
                <input
                    type={inputConfig.type}
                    key={this.props.name}
                    name={this.props.displayName}
                    value={inputConfig.value}
                    step={inputConfig.step}
                    readOnly={this.props.readOnly}
                    onChange={(event) => this.onChange(event)}></input>
            </div>
        );
    }

    getInputConfig(): { type: string, step: string, value:string } {
        const type = this.props.type;
        if (type == 'Decimal') { return { type: 'number', step: '0.01', value: this.props.value }; }

        return { type: type, step: 'any', value: this.props.value };
    }

    onChange(event: any): void {
        this.props.onChange(event);
    }
}
