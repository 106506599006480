export interface IApplicationContext{
    baseUrl: string;
    assetsUrl: string;
    userName: string;
    role:string;
    baseName: string;
}

export class ApplicationContext implements IApplicationContext {
    appCtx: IApplicationContext;

    constructor(appCtx: IApplicationContext) {
        this.appCtx = appCtx;
    }

    get baseUrl():string {
        return this.appCtx.baseUrl;
    }

    get assetsUrl():string {
        return this.appCtx.assetsUrl;
    }

    get userName(): string {
        return this.appCtx.userName;
    }

    get role():string {
        return this.appCtx.role;
    }

    get baseName():string {
        return this.appCtx.baseName;
    }

    public isLogged() {
        return this.userName.length > 0;
    }

    public buildUrl(url: string): string {
        return this.getUrl(url, this.baseUrl);
    }

    public buildAssetsUrl(url :string):string {
        return this.getUrl(url, this.appCtx.assetsUrl);
    }

    private getUrl(url: string, baseUrl: string):string {
        url = '/' + url;
        url = url.replace('//', '/');
        return baseUrl + url;
    }
}
