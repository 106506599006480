import { ApplicationContext } from '../shared/ApplicationContext';
import { EditModelContext } from './EditModelContext';
import Button from '../components/button/Button';
import { IEditViewModel } from './IEditViewModel';
import { IEditModelProperty } from '../index/IndexModel';
import * as React from 'react';
import axios from 'axios';
import classes from '../tools/classes';
import * as style from '../../../styledComponent/style.css';
import TabEdit from './TabEdit';
import { IButtonModel } from '../components/button/IButtonModel';
import { IButtonActionType } from '../components/button/IButtonActionType';
import Snackbar from '../components/snackBar/Snackbar';
import { MessageType } from '../components/snackBar/MessageType';
import { IBreadcrumb } from '../shared/models/IBreadcrumb';
import Breadcrumbs from '../components/Breadcrumbs';
import Modal from '../components/modal/Modal';
import { ModalService } from '../components/modal/ModalService';
import { IButtonSlugs } from '../components/button/IButtonSlugs';

export interface IEditProps {
    controller: string;
    appCtx: ApplicationContext;
    id: number;
}

export interface IEditState {
    editModel: any;
    properties: IEditModelProperty[];
    tabs: any;
    editButtons: IButtonModel[];
    saveInProgress: boolean;
    activeTab: string;
    layout: boolean;
    saveUrl: string;
    indexUrl: string;
    messages: string[];
    breadcrumbs: IBreadcrumb[];
    showModal: boolean;
    modalContent: React.ReactElement;
}

export default class EditComponent<IP extends IEditProps, IS extends IEditState> extends React.Component<IEditProps, IEditState> {
    constructor(props: IEditProps) {
        super(props);

        this.state = {
            showModal: false,
            modalContent: <></>,
            breadcrumbs: [],
            editModel: null,
            properties: [],
            tabs: [],
            editButtons: null,
            saveInProgress: false,
            activeTab: null,
            layout: false,
            saveUrl: '',
            indexUrl: '',
            messages: []
        };
    }

    protected modalService: ModalService = ModalService.Instance();

    protected axios = axios;
    componentDidMount() {
        document.addEventListener('keydown', (e) => this.onKeyDownEvent(e));
        this.registerModal();

        const url = this.buildUrl(`${this.props.controller}/EditData/${this.props.id}`);
        this.axios.get(url)
            .then(response => { this.onEditDataChanged(response.data); })
            .catch(error => {
                const message = error.response.data.message;
                this.processMessages([message]);
                this.processRedirectIf(error.response.data);
            });
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', (e) => this.onKeyDownEvent(e));
    }

    onKeyDownEvent(e: KeyboardEvent): any {
        if (e.ctrlKey) {
            if (e.key === 'F12') {
                const button = this.state.editButtons.filter(f => f.slug === IButtonSlugs.Save)[0];
                if (button) {
                    this.saveClick(null, button.url);
                }
            }
            if (e.key === 'Delete') {
                const button = this.state.editButtons.filter(f => f.slug === IButtonSlugs.Delete)[0];
                if (button) {
                    this.axios.get(button.url).then(() => {
                        window.location.href = button.successUrl;
                    });
                }
            }
        }
    }

    registerModal() {
        this.modalService.register((arg) => this.setState({ modalContent: arg(), showModal: true }), () => this.setState({ showModal: false }));
    }

    onEditDataChanged(data: any) {
        const vm = data as IEditViewModel;
        this.setState({
            editButtons: vm.editButtonsCollection,
            editModel: vm.editModel,
            properties: vm.properties,
            saveUrl: vm.saveUrl,
            breadcrumbs: vm.breadcrumbs

        }, () => {
            const currentTab = this.getUriHash() || vm.properties.filter(p => p.name != 'id')[0].name || '';
            this.onTabClick(currentTab);
        });
    }

    protected editContext(): EditModelContext {
        return null;
    }

    saveToStore(key, value) {
        // editModelStore.setValue(key, value)
        const model = this.state.editModel;
        model[key] = value;
        this.setState({ editModel: model });
    }

    clearMessage() {

        // this.setState({ messages: [] })
    }

    protected buildUrl(uri: string): string {
        return this.props.appCtx.buildUrl(uri);
    }

    saveClick(event, url) {
        this.setState({ saveInProgress: true });
        const saveUrl = this.buildUrl(url);
        this.axios.post(saveUrl, this.state.editModel)
            .then(result => {
                if (result.status === 200) {
                    const data = result.data;
                    this.processRedirectIf(data);
                }
                const messages = JSON.parse(result.headers.messages);
                this.processMessages(messages);
            })
            .catch(error => {
                const messages = JSON.parse(error.response.headers.messages);
                this.processMessages(messages);
            });
    }

    processMessages(messages: string[]) {
        this.setState({ messages: messages }, () => {
            window.setTimeout(() =>
                // console.log(messages), 300000);
                this.setState({ messages: [] }), 3000);
        }
        );
        this.setState({ saveInProgress: false });
    }

    processRedirectIf(data) {
        const redirect = data.redirectUrl || null;
        if (redirect !== null) {
            window.setTimeout(() => window.location.href = this.props.appCtx.buildUrl(redirect), 7500);
        } else {
            this.setState({ editModel: data.model });
        }
    }

    urlClick(event, url) {
        // url = UrlBuilder.buildUrl(url);
        window.location.href = url;
    }

    protected postButtonClick(event, button: IButtonModel) {

    }

    createButtons(buttonsCollection: IButtonModel[]) {
        return (
            buttonsCollection.map((btt, index) => {
                let action;
                if (btt.actionType === IButtonActionType.SaveAction) { action = e => this.saveClick(e, btt.url); }
                if (btt.actionType === IButtonActionType.UrlLink) { action = e => this.urlClick(e, btt.url); }
                if (btt.actionType === IButtonActionType.PostMethod) { action = e => this.postButtonClick(e, btt); }
                return <Button
                    key={index}
                    title={btt.title}
                    disabled={this.state.saveInProgress}
                    action={() => action()} />;
            }
            )
        );
    }

    onTabClick(tabName) {
        this.setState({ activeTab: tabName });
        window.location.hash = tabName;
    }

    getUriHash(): string {
        const hash = window.location.hash.substring(1);
        if (hash && hash.length > 0) {
            const property = this.state.properties.find(p => p.name == hash);
            if (property != null) { return property.name; }
        }
        return null;
    }

    render() {
        let properties = this.state.properties || [];
        properties = properties.filter(p => p.meta.visible && p.meta.dontScaffold === false);
        return (<div className={style.editForm} >
            <div className={this.state.showModal ? style.isBlurred : ''}>

                {
                    this.state.editButtons &&
                    <div className={style.editButtons}>
                        {this.createButtons(this.state.editButtons)}
                    </div>
                }

                {this.state.breadcrumbs &&

                    <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
                }
                <div className={style.dataFields}>

                    <div className={style.tabs}>
                        {properties.map(t => <div key={t.name} className={classes((this.state.activeTab === t.name ? style.active : ''), style.tab)} onClick={() => this.onTabClick(t.name)}>{t.meta.displayName}</div>)}
                    </div>
                    <div className={style.formWrapper}>

                        {properties.filter(t => t.name === this.state.activeTab).map((viewProperty, i) => this.getTabEditComponent(viewProperty, i))}
                    </div>
                </div>
                <Snackbar messages={this.state.messages} messageType={MessageType.Success} />
            </div>
            <Modal show={this.state.showModal} onClose={() => { }}>
                {this.state.modalContent}
            </Modal>
        </div>
        );
    }

    protected getTabEditComponent(viewProperty: IEditModelProperty, index: number) {
        if (viewProperty.meta.dontScaffold) { return <></>; }
        return <TabEdit
            key={index}
            onModelChange={(k: string, v: any) => this.saveToStore(k, v)}
            editModel={this.state.editModel[viewProperty.name]}
            viewModel={viewProperty}
            appCtx={this.props.appCtx}
            modelId={this.props.id}
        />;
    }

    protected addToCollection(key: string, collection: any): void {
        const viewModels = collection.viewModels;
        const property = this.state.properties.filter(x => x.name == key)[0];
        for (let i = 0; i < viewModels.length; i++) {
            (property.viewModelProperty as any).viewModels.push(viewModels[i]);
        }
        const editModel = this.state.editModel;
        for (let i = 0; i < collection.models.length; i++) {
            editModel[key].push(collection.models[i]);
        }
        this.setState({ editModel: editModel });
    }
}
