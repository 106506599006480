import TabEdit, { ITabProps, ITabState } from '../framework/framework/edit/TabEdit';
import Button from '../framework/framework/components/button/Button';
import { ModalService } from '../framework/framework/components/modal/ModalService';
import axios from 'axios';
import { IEditModelProperty } from '../framework/framework/index/IndexModel';
import CollectionComponent from '../framework/framework/components/basic/CollectionComponent';
import { IParameterValue, ParameterType } from './parameters/IParameterValue';
import ParametersIndex from '../parameters/ParametersIndex';
import { ParameterComponentFactory } from './ParameterComponentFactory';
import React = require('react');

export class ParametersTab extends TabEdit<ITabProps, ITabState> {
    protected renderTabHeader(): React.ReactNode {
        return <>
            <Button
                title={'Dodaj'}
                disabled={false}
                action={() => this.onAddFile()} />
        </>;
    }

    protected renderTabContent() {
        const value = this.props.editModel;
        const meta = this.props.viewModel.meta;
        const property = this.props.viewModel;
        return <CollectionComponent items={value}
            meta={meta}
            property={property}
            columns={['Nazwa', 'Wartość', 'Jedn.']}
            valueChangedAction={(name: string, value: any) => this.onValueChange(name, value)}
            classNames={''}
            onItemClick={item => this.onItemClick(item)}
            cellValueRenderer={(p: IEditModelProperty, index: number, item: any) => this.renderCellContent(p, index, item)}
            getViewModel={(vm, item, index) => this.getViewModelProperties(vm, item, index)}
        >
            {/* {
            // subComponents.map(c => this.GetComponent({ Meta: { Component: c } }, value, frontProps))
        } */}
        </CollectionComponent>;
    }

    protected getViewModelProperties(viewModelProperty: any, item:any, index:number):IEditModelProperty[] {
        return viewModelProperty.viewModels.filter(p => p.model.id === item.id)[0].properties as IEditModelProperty[];
    }

    renderCellContent(p: IEditModelProperty, index: number, item: any) {
        if (p.name === 'name') { return item.name; }

        if (p.name === 'value' || p.name === 'values') {
            const parameter = this.parameterComponent(p, item, index);
            return parameter;
        }

        if (p.name === 'unit') { return item.unit || ''; }
    }

    parameterComponent(p: IEditModelProperty, item: IParameterValue, index: number) {
        return ParameterComponentFactory.GetComponent(p, item, item => this.onParameterChanged(item));
    }

    onParameterChanged(item: IParameterValue) {
        const items = this.props.editModel as IParameterValue[];

        for (let i = 0; i < items.length; i++) {
            if (items[i].id === item.id && items[i].parameterId === item.parameterId) {
                if (item.type === ParameterType.Single) {
                    items[i].value = item.value;
                } else {
                    items[i].values = item.values;
                }
            }
        }

        this.onModelChangeInternal(items);
    }

    onParameterChange(key: string, value: any, item: any): void {
    }

    onValueChange(name: string, value: any): void {
        this.onModelChangeInternal(value);
    }

    modalService = ModalService.Instance();

    protected onItemClick(item: any): void {
        console.log(item);
        // window.location.href = this.buildUrl(`message/edit/${item.id}`)
    }

    onAddFile(): void {
        this.modalService.Open(() =>
            <>
                <ParametersIndex
                    indexOption={{ modal: true, multiSelect: true, onClose: (selected) => this.onSelectedClick(selected) }}
                    controller={'Parameters'}
                    appCtx={this.props.appCtx}

                />
            </>

        );
    }

    onSelectedClick(selected: number[]): void {
        this.modalService.Close();
        const url = this.props.appCtx.buildUrl('product/addParameters');
        const model = { selected: selected };

        axios.post(url, model)
            .then(result => {
                this.addItems(result.data as any[]);
            })
            .catch(error => {
                console.log(error);
            }
            );
    }

    addItems(items: any[]) {
        this.props.onCollectionAdd(this.props.viewModel.name, items);
    }

    onCancelClick() {
        this.modalService.Close();
    }
}
