// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-taxonomyIndex-style__name--2Yo7U{\r\n   display: flex;\r\n\r\n}\r\n\r\n.src-taxonomyIndex-style__arrow--1Xezj{\r\n   margin-right: 1rem;\r\n   cursor: pointer;\r\n   width: 24px;\r\n   background: #dadada;\r\n   text-align: center;\r\n   border-radius: 5px;\r\n}\r\n.src-taxonomyIndex-style__tab--23OZo{\r\n   width: 20px;\r\n}", "",{"version":3,"sources":["webpack://src/taxonomyIndex/style.css"],"names":[],"mappings":"AAAA;GACG,aAAa;;AAEhB;;AAEA;GACG,kBAAkB;GAClB,eAAe;GACf,WAAW;GACX,mBAAmB;GACnB,kBAAkB;GAClB,kBAAkB;AACrB;AACA;GACG,WAAW;AACd","sourcesContent":[".name{\r\n   display: flex;\r\n\r\n}\r\n\r\n.arrow{\r\n   margin-right: 1rem;\r\n   cursor: pointer;\r\n   width: 24px;\r\n   background: #dadada;\r\n   text-align: center;\r\n   border-radius: 5px;\r\n}\r\n.tab{\r\n   width: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"name": "src-taxonomyIndex-style__name--2Yo7U",
	"arrow": "src-taxonomyIndex-style__arrow--1Xezj",
	"tab": "src-taxonomyIndex-style__tab--23OZo"
};
export default ___CSS_LOADER_EXPORT___;
