
export default function parseUrlParam(url: string, o: any):string {
    const test = /{([a-z]+)}/;
    const groups = url.match(test);
    const param = groups[1];
    if (param !== undefined) {
        const value = o[param];
        if (value !== undefined) { url = url.replace(groups[0], value); }
    }
    return url;
}
