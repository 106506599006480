import * as style from '../../../../styledComponent/style.css';
import * as local from './style.css';
import classes from '../../tools/classes';
import { ActionT } from '../Types';
import React = require('react');

interface IProps {
    disabled?: boolean;

    multiple?: boolean;
    onFilesAdded: ActionT<any[]>
}
interface IState {
    highlight: boolean

}

export default class FileUploader extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = { highlight: false };
        this.fileInputRef = React.createRef();
    }

    fileInputRef: React.RefObject<any>;

    render() {
        return (
            <div className={classes(style.withBorder, style.container)}>
                <div className={classes(local.dropZone, this.state.highlight ? local.highlight : '')}
                    onClick={() => this.openFileDialog()}
                    onDragOver={(e) => this.onDragOver(e)}
                    onDragLeave={(e) => this.onDragLeave(e)}
                    onDrop={(e) => this.onDrop(e)}

                >
                    <span className="material-icons">
                        cloud_upload</span>
                    Upload files
                    <input
                        ref={this.fileInputRef}
                        type="file"
                        hidden
                        multiple={this.props.multiple}
                        onChange={(e) => this.onFileChange(e)} />
                </div>

                <div>
                    {this.props.children}
                </div>
            </div>
        );
    }

    onDrop(e: React.DragEvent<HTMLDivElement>): void {
        e.preventDefault();

        if (this.props.disabled) { return; }

        if (this.props.onFilesAdded) {
            const files = e.dataTransfer.files;
            const array = this.fileListToArray(files);
            this.props.onFilesAdded(array);
        }

        this.setState({ highlight: false });
    }

    onDragLeave(e: React.DragEvent<HTMLDivElement>): void {
        this.setState({ highlight: false });
    }

    onDragOver(e: React.DragEvent<HTMLDivElement>): void {
        e.preventDefault();
        if (this.props.disabled) { return; }

        this.setState({ highlight: true });
    }

    openFileDialog(): void {
        if (this.props.disabled) return;
        this.fileInputRef.current.click();
    }

    onFileChange(e: React.ChangeEvent<HTMLInputElement>): void {
        if (this.props.disabled) return;
        const files = e.target.files;
        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files);
            this.props.onFilesAdded(array);
        }
    }

    fileListToArray(list) {
        const array = [];
        for (let i = 0; i < list.length; i++) {
            array.push(list.item(i));
        }
        return array;
    }
}
