// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-files-style__fileList--2i5yV {\r\n    display: flex;\r\n    flex-direction: column;\r\n\r\n}", "",{"version":3,"sources":["webpack://src/files/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;;AAE1B","sourcesContent":[".fileList {\r\n    display: flex;\r\n    flex-direction: column;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileList": "src-files-style__fileList--2i5yV"
};
export default ___CSS_LOADER_EXPORT___;
