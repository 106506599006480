import ComponentFactory from '../components/basic/ComponentFactory';
import { ValueChanged } from '../components/Types';
import { IEditModelProperty } from '../index/IndexModel';
import { ApplicationContext } from '../shared/ApplicationContext';
import React = require('react');

export interface ITabProps {
    onModelChange: ValueChanged;
    editModel: any;
    viewModel: IEditModelProperty;
    appCtx: ApplicationContext;
    modelId: number;
    onCollectionAdd?: ValueChanged;
}
export interface ITabState {

}

export default class TabEdit<IP extends ITabProps, IS extends ITabState> extends React.Component<IP, IS> {
    render() {
        return <>
            <div className={'data-field-tab'}>
                {this.renderTabHeader()}
                {this.renderTabContent()}
                {this.renderTabFooter()}

            </div>
        </>;
    }

    renderTabFooter(): React.ReactNode {
        return <></>;
    }

    protected renderTabHeader(): React.ReactNode {
        return <></>;
    }

    protected renderTabContent() {
        if (this.props.viewModel.meta.component === 'CollectionComponent') {
            return ComponentFactory.GetComponent(this.props.viewModel,
                this.props.editModel,
                {
                    onModelChange: (k, v) => this.props.onModelChange(this.props.viewModel.name, v),
                    onModelClick: (item: any) => this.onItemClick(item),
                    key: ''
                });
        }

        return this.props.viewModel.viewModelProperty.properties.map((p, index) => this.getComponent(p, index));
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected onItemClick(item: any): void {

    }

    protected buildUrl(uri: string) {
        return this.props.appCtx.buildUrl(uri);
    }

    protected getComponent(p: IEditModelProperty, index: number) {
        if (p.meta.visible === false) { return <></>; }

        let componentValue = this.getValue(p.meta.name);
        if (componentValue === undefined) { componentValue = p.model; }
        const component = ComponentFactory.GetComponent(p, componentValue, {
            key: index.toString(),
            onModelChange: (k, v) => this.onModelChange(k, v)
        });
        return component;
    }

    onModelChange(key: any, value: any) {
        const editModel = this.props.editModel;
        editModel[key] = value;
        this.props.onModelChange(this.props.viewModel.name, editModel);
    }

    protected onModelChangeInternal(value: any) {
        this.props.onModelChange(this.props.viewModel.name, value);
    }

    getValue(name) {
        if (name === undefined) { return undefined; }
        const model = this.props.editModel;
        const value = model[name]; // undefined for wrong prop name
        return value;
    }
}
