import { IMeta } from '../../index/IndexModel';
import React = require('react');

interface IProps{
    meta:IMeta;
}
export default class Comment extends React.Component<IProps, {}> {
    render() {
        if (this.props.meta.comments == null) { return (<React.Fragment />); }

        return (

            <div className="data-input comments">
                {this.props.meta.comments}
            </div>
        );
    }
}
