import * as style from '../../../../styledComponent/style.css';
import React = require('react');

interface IProps {
    disabled: boolean;
    action: () => void;
    title: string;
}

interface IState { }

export default class Button extends React.Component<IProps, IState> {
    render() {
        return (

            <button disabled={this.props.disabled} className={style.editButton} type="button" onClick={this.props.action} >{this.props.title}</button>
        );
    }
}
