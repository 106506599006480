
import { IMeta } from '../../index/IndexModel';
import { ISelectItemModel } from '../dataModels/ISelectItemModel';
import { ValueChanged } from '../Types';
import * as style from '../../../../styledComponent/style.css';
import classes from '../../tools/classes';
import React = require('react');

interface IProps {
    items: ISelectItemModel[];
    valueChangedAction: ValueChanged;
    value: any;
    meta: IMeta;
    classNames: string;
    withLabel: boolean;
}

interface IState {
    readOnly: boolean;
}
export default class SingleSelect extends React.Component<IProps, IState> {
    constructor(prop: IProps) {
        super(prop);

        this.state = {
            readOnly: prop.meta.readOnly
        };
    }

    valueChanged = (event: any) => {
        if (this.props.valueChangedAction) {
            const optionIndex = event.target.selectedIndex;
            this.props.valueChangedAction(this.props.meta.name, this.props.items[optionIndex].value);
        }
    }

    render() {
        const classNames = classes(style.dataInput, style.singleSelect);
        const options = this.props.items;
        const meta = this.props.meta;
        const selected = options.filter(o => o.value === this.props.value)[0] || options[0];

        const readOnlyValue = selected.displayValue || '';

        return (
            <div className={classNames}>
                {this.props.withLabel && <div className={style.dataLabel}>
                    {this.props.meta.displayName}
                </div>}
                {this.state.readOnly
                    ? <div className={'readOnly ' + meta.name + ' data '}>
                        <input type="Text" key={meta.name} name={meta.displayName} value={readOnlyValue} readOnly={true} ></input>
                    </div>
                    : <select className="data-input" value={selected.value} onChange={e => this.valueChanged(e)}>
                        {options.map(i => <option key={i.value} value={i.value} >{i.displayValue}</option>)}

                    </select>
                }
            </div>
        );
    }
}
