// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-import-style__importContainer--rgJd5{\r\n   margin: 100px;\r\n}\r\n.src-import-style__header--IyUqL{\r\n    padding: 1rem 0;\r\n    font-size: 2rem;\r\n}\r\n\r\n.src-import-style__fileList--O4L44 {\r\n    display: flex;\r\n    flex-direction: column;\r\n\r\n}\r\n.src-import-style__success--3jGxD{\r\n    padding: 10px 0;\r\n    color: green;\r\n}\r\n.src-import-style__actions--1V0lG{\r\n    margin: 20px;\r\n    display: flex;\r\n    flex-direction: row;\r\n    flex-wrap: wrap;\r\n    gap: 20px;\r\n}\r\n.src-import-style__action--30yWN{\r\n    border: 2px solid #c0c0c0;\r\n    border-radius: 5px;\r\n    padding: 30px;\r\n    min-width: 200px;\r\n    text-align: center;\r\n}\r\n\r\n.src-import-style__action--30yWN:hover{\r\n    background: #e0e0e0;\r\n}", "",{"version":3,"sources":["webpack://src/import/style.css"],"names":[],"mappings":"AAAA;GACG,aAAa;AAChB;AACA;IACI,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;;AAE1B;AACA;IACI,eAAe;IACf,YAAY;AAChB;AACA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,SAAS;AACb;AACA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".importContainer{\r\n   margin: 100px;\r\n}\r\n.header{\r\n    padding: 1rem 0;\r\n    font-size: 2rem;\r\n}\r\n\r\n.fileList {\r\n    display: flex;\r\n    flex-direction: column;\r\n\r\n}\r\n.success{\r\n    padding: 10px 0;\r\n    color: green;\r\n}\r\n.actions{\r\n    margin: 20px;\r\n    display: flex;\r\n    flex-direction: row;\r\n    flex-wrap: wrap;\r\n    gap: 20px;\r\n}\r\n.action{\r\n    border: 2px solid #c0c0c0;\r\n    border-radius: 5px;\r\n    padding: 30px;\r\n    min-width: 200px;\r\n    text-align: center;\r\n}\r\n\r\n.action:hover{\r\n    background: #e0e0e0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"importContainer": "src-import-style__importContainer--rgJd5",
	"header": "src-import-style__header--IyUqL",
	"fileList": "src-import-style__fileList--O4L44",
	"success": "src-import-style__success--3jGxD",
	"actions": "src-import-style__actions--1V0lG",
	"action": "src-import-style__action--30yWN"
};
export default ___CSS_LOADER_EXPORT___;
