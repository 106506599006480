import { IMeta } from '../../index/IndexModel';
import classes from '../../tools/classes';
import { Action, ValueChanged } from '../Types';
import * as style from '../../../../styledComponent/style.css';
import React = require('react');

interface IProps {
    onClick: Action;
    property: IMeta;
    classNames?: string;
    value: any;
    withLabel?: boolean;
}

interface IState {
    meta: IMeta
    valueObject: any;
}

export default class UriLink extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            meta: this.props.property,
            valueObject: this.props.value || '...'
        };
    }

    render() {
        const classNames = classes(style.dataInput, this.props.classNames || '');
        const meta = this.state.meta;

        return (
            <div className={classNames}>
                {this.props.withLabel && <div className={style.dataLabel}>
                    {meta.displayName}
                </div>}
                <div className={meta.name + ' data '}>
                    <div onClick={() => this.props.onClick()} >{this.props.value} </div>
                </div>
            </div>
        );
    }
}
