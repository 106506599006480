import React = require("react");
import { ApplicationContext } from "../framework/framework/shared/ApplicationContext";
import MenuBar from "../menuBar/MenuBar";

interface IProps {
    appCtx: ApplicationContext;
}

interface IState {
    role: string;

}

export const RoleContext = React.createContext({ role: "" });

export default class Application extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)

        this.state = { role: "" }
    }



    render() {
        return (
            <>
                <MenuBar uriBuilder={(uri: string) => this.urlBuilder(uri)} userName={this.props.appCtx.userName} role={this.props.appCtx.role} isLogged={this.props.appCtx.isLogged()} />
                {this.props.children}
            </>
        );

    }
    urlBuilder(uri: string) {
        return this.props.appCtx.buildUrl(uri);
    }

}