import { Decimal } from './decimal/Decimal';

export default class CustomComponents {
    static getComponent(prop, value): JSX.Element {
        let name = null;
        if (prop.meta.component) {
            name = prop.meta.component.name;
        }
        if (name === null) {
            return;
        }
        if (name === 'Decimal') {
            return Decimal(value);
        }

        return null;
    }
}
