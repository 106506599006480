import * as style from '../../../../styledComponent/style.css';
import classes from '../../tools/classes';
import { MessageType } from './MessageType';
import React = require('react');

interface IProps {
    messages: string[];
    onTimeout?: ()=>void;
    messageType: MessageType;
}
interface IState {

}
export default class Snackbar extends React.Component<IProps, IState> {
    render() {
        const status = this.getStatusClass();
        if (this.props.messages && this.props.messages.length > 0) {
            return <>
                <div className={classes(style.snackBars)}>
                    {this.props.messages.map((m, i) => this.getMessageSnackBar(m, i, status))}
                </div>
            </>;
        }
        return <></>;
    }

    getStatusClass():string {
        if (this.props.messageType === MessageType.Success) { return style.success; }
    }

    getMessageSnackBar(m: string, index:number, status: string): any {
        return <div key={index} className={classes(style.snackBar, status) } >
            {m}
        </div>;
    }
}
