
import { IPagingModel } from './IPagingModel';
import * as style from '../../../../styledComponent/style.css';
import React = require('react');

interface IProps {
    paging: IPagingModel;
    onPageClick: (page: number) => void;
}

export default class IndexPaging extends React.Component<IProps, {}> {
    render() {
        const currentPage = this.props.paging.pageNumber;
        const lastPage = this.props.paging.pages;
        const nextPageVisible = currentPage < lastPage;
        const prevPageVisible = currentPage > 1;
        const lastVisible = currentPage < lastPage;

        return (
            <div className={style.paging}>

                <div className={style.results}>
                    {'Results' + ':' + this.props.paging.results}
                </div>
                <div className={style.buttons}>
                    {prevPageVisible && <div className={style.button} onClick={() => this.props.onPageClick(1)}>{1}</div>}
                    {prevPageVisible && <div className={style.button} onClick={() => this.props.onPageClick(currentPage - 1)}>{'<<'}</div>}
                    <div >{currentPage}</div>
                    {nextPageVisible && <div className={style.button} onClick={() => this.props.onPageClick(currentPage + 1)}>{'>>'}</div>}
                    {lastVisible && <div className={style.button} onClick={() => this.props.onPageClick(lastPage)}>{lastPage}</div>}
                </div>

                <div className={style.perPage}>
                    Per page: {this.props.paging.rowsPerPage}
                </div>

            </div>
        );
    }
}
