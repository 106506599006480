import TabEdit, { ITabProps, ITabState } from "../framework/framework/edit/TabEdit";
import React = require("react");
import Button from "../framework/framework/components/button/Button";
import { ModalService } from "../framework/framework/components/modal/ModalService";
import axios from 'axios';




export class ValuesTab extends TabEdit<ITabProps, ITabState> {
    constructor(props: ITabProps){
        super(props)
    }

    protected renderTabHeader(): React.ReactNode {
        return <>
            <Button
                title={'New value'}
                disabled={false}
                action={() => this.onNewMessageClick()} />
        </>
    }

    modalService = ModalService.Instance();

   

    onNewMessageClick(): void {
        let id= this.props.modelId;
        let url = this.props.appCtx.buildUrl(`dictionary/NewValue`);
        axios.post(url, {})
        .then(result => {
            this.addItems(result.data as any[]);
        })
        .catch(error => {
            console.log(error);

        }
        );
    }
   
    addItems(items: any[]) {
        this.props.onCollectionAdd(this.props.viewModel.name, items);
    }


}

