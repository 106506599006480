import { Action, Func } from '../Types';
import React = require('react');

export class ModalService {
    private static _instance: ModalService = null;
    renderer: (render: () => React.ReactElement) => void;
    closeAction: Action;

    static Instance() {
        if (ModalService._instance == null) { ModalService._instance = new ModalService(); }

        return ModalService._instance;
    }

    public register(renderer: (arg:Func< React.ReactElement>)=>void, closeAction: Action): void {
        this.renderer = renderer;
        this.closeAction = closeAction;
    }

    public Open(render: Func< React.ReactElement>) {
        this.renderer(render);
    }

    public Close() {
        this.closeAction();
    }
}
