import ComponentFactory from '../../framework/framework/components/basic/ComponentFactory';
import { ParameterBase } from './ParameterBase';
import React = require('react');

export default class DictionaryMultiParameter extends ParameterBase {
    super() {
    }

    protected renderView(): JSX.Element {
        let values = [];
        if (this.props.value && this.props.value.values) {
            values = this.props.value.values;
        }
        return <>
            {ComponentFactory.GetRawComponent(this.props.property, values,
                { onModelChange: (k, v) => this.onModelChange(k, v), key: this.props.value.parameterId.toString() })}

        </>;
    }

    onModelChange(k: string, v: any): void {
        const item = this.props.value;
        const value = parseInt(v);
        if (item.values.some(x => x === value)) {
            item.values = item.values.filter(x => x !== value);
        } else {
            item.values.push(value);
        }

        this.props.onChange(item);
    }
}
