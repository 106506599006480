// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-framework-framework-index-resultTable-style__indexResultTable--3D_Bs {\r\n    margin-top: 24px;\r\n    border: 2px solid white;\r\n    border-radius: 6px;\r\n    padding: 6px;\r\n    \r\n    background: #ffffff;\r\n  }\r\n\r\n\r\n  .src-framework-framework-index-resultTable-style__indexResultTable--3D_Bs table tr{\r\n    cursor: pointer;\r\n  }\r\n \r\n", "",{"version":3,"sources":["webpack://src/framework/framework/index/resultTable/style.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;;IAEZ,mBAAmB;EACrB;;;EAGA;IACE,eAAe;EACjB","sourcesContent":[".indexResultTable {\r\n    margin-top: 24px;\r\n    border: 2px solid white;\r\n    border-radius: 6px;\r\n    padding: 6px;\r\n    \r\n    background: #ffffff;\r\n  }\r\n\r\n\r\n  .indexResultTable table tr{\r\n    cursor: pointer;\r\n  }\r\n \r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"indexResultTable": "src-framework-framework-index-resultTable-style__indexResultTable--3D_Bs"
};
export default ___CSS_LOADER_EXPORT___;
