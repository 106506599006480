import ComponentFactory from '../../framework/framework/components/basic/ComponentFactory';
import { ParameterBase } from './ParameterBase';
import * as style from '../../styledComponent/style.css';
import React = require('react');

export default class TextMultiParameter extends ParameterBase {
    super() {
    }

    protected renderView(): JSX.Element {
        return <>
            {this.props.value.values.map((v, i) =>
                <div className={style.parameters} key={i}>
                    {ComponentFactory.GetRawComponent(this.props.property, v,
                        { onModelChange: (k, v) => this.onItemChange(i, v), key: i.toString() })}
                    <div className={style.parameterActionIcon} onClick={() => this.onRemoveItem(i)}>X</div>
                    {this.props.value.values.length - 1 == i &&
                        <div className={style.parameterActionIcon} onClick={() => this.onAddItem()}>+</div>
                    }

                </div>
            )}
        </>;
    }

    onItemChange(index: number, v: any): void {
        const values = this.props.value.values;
        values[index] = v;
        this.onModelChange('', values);
    }

    onAddItem(): void {
        const values = this.props.value.values;
        values.push(null);
        this.onModelChange('', values);
    }

    onRemoveItem(index: number): void {
        const item = this.props.value;
        const values = item.values.filter((v, i) => i != index);
        this.onModelChange('', values);
    }

    onModelChange(k: string, v: any): void {
        const item = this.props.value;
        item.values = v;
        this.props.onChange(item);
    }
}
