import React = require("react");
import ComponentFactory from "../../framework/framework/components/basic/ComponentFactory";
import { ParameterBase } from "./ParameterBase";


export default class NumericRangeParameter extends ParameterBase {
    super() {
    }

    protected renderView(): JSX.Element {

        return <>
            {ComponentFactory.GetRawComponent(this.props.property, this.props.value.values[0],
                { onModelChange: (k, v) => this.onItemChange(0, v), key: "0" })}
            {ComponentFactory.GetRawComponent(this.props.property, this.props.value.values[1],
                { onModelChange: (k, v) => this.onItemChange(1, v), key: "1" })}

        </>
            ;

    }

    onItemChange(index: number, v: any) {
        let values = this.props.value.values;
        values[index] = v;
        this.onModelChange("", values);

    }
    onModelChange(k: string, v: any): void {

        let item = this.props.value;
        item.value = parseFloat(v);
        this.props.onChange(item);
    }
}
