import React = require("react");
import { ActionT } from "../../framework/framework/components/Types";
import { IEditModelProperty } from "../../framework/framework/index/IndexModel";

import { IParameterValue } from "./IParameterValue";


interface IProps {
    property: IEditModelProperty;
    value: IParameterValue;
    onChange: ActionT<IParameterValue>;
}
interface IState { }

export class ParameterBase extends React.Component<IProps, IState> {
    super(props: IProps) {

    }



    protected renderView(): JSX.Element {

        return <></>;
    }

    render() {
        return <div key={this.props.value.id}>
            {this.renderView()}

        </div>

    }
}




